import { Redirect } from "react-router-dom";
import { AppModel, DoitRole } from "@doitintl/cmp-models";
import { getCollection, useDocumentDataOnce } from "@doitintl/models-firestore";
import { Box } from "@mui/material";
import orderBy from "lodash/orderBy";

import { useDoitRoleCheck } from "../../Components/hooks/useDoitRoles";
import useRouteMatchURL from "../../Components/hooks/useRouteMatchURL";
import { AccountManagersHooks } from "../../Context/customer/AccountManagers";
import { type ContractsPageId } from "../../Navigation/config/pages";
import ContractForm from "../Contracts/ContractForm/ContractForm";
import ContractPage from "../Contracts/ContractView/ContractPage";
import VendorContractPage from "../Contracts/VendorContractView/VendorContractPage";
import RampPlan from "../RampPlans/RampPlan/RampPlan";
import { ContractsListTab } from "./Contracts";
import { RampsListTab } from "./Ramps";

type Props = {
  pageId: ContractsPageId;
};

export const ContractsTabs = (props: Props) => {
  const { pageId } = props;
  const { accountManager } = AccountManagersHooks.useAccountManager();
  const routeMatchURL = useRouteMatchURL();

  const [contractsMetadata] = useDocumentDataOnce(getCollection(AppModel).doc("contracts"));
  const contractProductTypes = orderBy(contractsMetadata?.options ?? [], (o) => o.label);

  const isDoitContractAdmin = useDoitRoleCheck(DoitRole.ContractAdmin);
  const isDoitContractOwner = useDoitRoleCheck(DoitRole.ContractOwner);

  if (pageId === "contracts") {
    return <Redirect to={`${routeMatchURL}/contracts-list`} />;
  }

  return (
    <Box ml={-1}>
      {pageId === "contracts:list" && <ContractsListTab productTypes={contractProductTypes} {...props} />}
      {(pageId === "contracts:create" || pageId === "contracts:edit") && (
        <ContractForm
          accountManager={accountManager}
          productTypes={contractProductTypes}
          isDoitContractAdmin={isDoitContractAdmin}
          isDoitContractOwner={isDoitContractOwner}
          {...props}
        />
      )}

      {pageId === "contracts:view" && <ContractPage productTypes={contractProductTypes} />}

      {pageId === "contracts:view-vendor-contract" && <VendorContractPage />}

      {pageId === "ramp-plans" && <RampsListTab />}

      {pageId === "ramp-plans:edit" && <RampPlan />}
    </Box>
  );
};
