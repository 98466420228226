import { type SyntheticEvent, useEffect, useMemo, useState } from "react";

import { AssetTypeGoogleCloud, AssetTypeLooker } from "@doitintl/cmp-models";
import { Alert, Autocomplete, Chip, Container, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import maxBy from "lodash/maxBy";
import values from "lodash/values";

import { type Asset, type Assets, type TAssetType } from "../../../../types/index";
import { type FormattedContract } from "../../ContractsList/types";
import { useCustomerContracts } from "../../hooks";
import { type ContractStateType } from "../types";
import { getContractLabel } from "../utils";
import { AssetsAutocomplete } from "./components/AssetsAutocomplete";

type Props = {
  assets: Assets<TAssetType>;
  state: ContractStateType;
  handleChangeNumber: (name: string) => ({ target }: { target: any }) => void;
  handleAssetsChange: (event: SyntheticEvent<Element, Event>, value: Asset<TAssetType>[]) => void;
  handleContractChange: (event: SyntheticEvent<Element, Event>, value: any) => void;
};

export const GcpPartnerLedPremiumSupport = ({
  assets,
  state,
  handleChangeNumber,
  handleAssetsChange,
  handleContractChange,
}: Props) => {
  const [contractOptions, setContractOptions] = useState<FormattedContract[]>([]);
  const { contracts } = useCustomerContracts();

  const selectedContracts = useMemo(
    () =>
      contractOptions.map((contract) => ({
        id: contract.id,
        type: contract.data.type,
        label: getContractLabel(contract),
      })),
    [contractOptions]
  );

  const selectedContractsValue = useMemo(
    () => selectedContracts.filter((option) => state.gcpContract.findIndex((v) => v.id === option.id) > -1),
    [selectedContracts, state.gcpContract]
  );

  const billingAccounts = useMemo(
    () =>
      values(assets)
        .flatMap((assetList) => assetList)
        .filter((asset) => asset.data.type === AssetTypeGoogleCloud),
    [assets]
  );

  const addMostRecentContracts = (gcpContracts, contractType, latestContracts) => {
    const contract = maxBy(
      gcpContracts.filter((contract) => contract.data.type === contractType),
      "data.startDate"
    );

    if (contract) {
      latestContracts.push(contract);
    }
  };

  useEffect(() => {
    if (!contractOptions.length) {
      const customEvent: SyntheticEvent<Element, Event> = new Event("change") as unknown as SyntheticEvent<
        Element,
        Event
      >;

      const gcpContracts = contracts.filter(
        (item) =>
          (item.data.type === AssetTypeGoogleCloud || item.data.type === AssetTypeLooker) &&
          item.data._status === "active"
      );

      const latestContracts: FormattedContract[] = [];
      addMostRecentContracts(gcpContracts, AssetTypeGoogleCloud, latestContracts);
      addMostRecentContracts(gcpContracts, AssetTypeLooker, latestContracts);

      setContractOptions(gcpContracts);
      if (state.gcpContract.length) {
        handleContractChange(customEvent, state.gcpContract);
      } else {
        handleContractChange(customEvent, latestContracts);
      }
    }
  }, [contractOptions.length, contracts, handleContractChange, state.gcpContract]);

  return (
    <Container maxWidth="sm" data-cy="contract-step">
      <Grid container spacing={1}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4" mb={1} mt={1}>
              Partner-Led Premium Support
            </Typography>
            <Autocomplete
              multiple
              filterSelectedOptions
              disableCloseOnSelect
              options={selectedContracts}
              getOptionLabel={(option) => option.label}
              fullWidth
              onChange={handleContractChange}
              value={selectedContractsValue}
              disabled={state.editMode}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Related Google Cloud contract"
                  required
                  margin="dense"
                  error={state.errors?.gcpContract}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip size="small" label={option.label} {...getTagProps({ index })} key={option.id} />
                ))
              }
            />
          </Grid>
          <Grid item xs={12}>
            <AssetsAutocomplete
              assets={billingAccounts}
              value={state.assets}
              onChange={handleAssetsChange}
              disabled={state.isEditForbidden || state.editMode}
              label="Billing Account(s)"
              textFieldProps={{
                error: state.errors?.assets,
                required: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              value={state.plpsPercent}
              onChange={handleChangeNumber("plpsPercent")}
              label="Customer charge"
              margin="dense"
              type="number"
              helperText="Charge can be between 0%-10%"
              required
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              error={state.errors?.plpsPercent}
              disabled={state.editMode}
            />
          </Grid>
          <Grid item xs={12}>
            <Alert severity="info">
              Selecting multiple Billing Accounts will create a Partner-Led Premium Support contract for each Billing
              Account
            </Alert>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
