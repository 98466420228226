import { useEffect, useState } from "react";

import { type CloudFlowNodeType } from "@doitintl/cmp-models";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { Card, CardHeader, Stack, useTheme } from "@mui/material";

import { cloudflowTexts } from "../../../../../assets/texts";
import { type NodeWitOutputModel } from "../../ApiActionParametersForm/parameters/wrappers/ReferencedField/useReferencedFieldContext";
import { useReferenceableNodes } from "../../Common/hooks/useReferenceableNodes";
import ConditionForm from "../../FilterAction/ConditionForm";
import FieldReferenceForm from "../../FilterAction/FieldReferenceForm";
import SwitchDataSourceDialog from "../../FilterAction/SwitchDataSourceDialog";
import { useNodeConfigurationContext } from "../NodeConfigurationContext";

const FilterParametersTab = () => {
  const { nodeConfig } = useNodeConfigurationContext<CloudFlowNodeType.FILTER>();
  const referenceableNodes = useReferenceableNodes(nodeConfig.id);

  const [selectedNode, setSelectedNode] = useState<NodeWitOutputModel | undefined>();

  const [switchDataSourceOpen, setSwitchDataSourceOpen] = useState(false);

  const [handleConfirm, setHandleConfirm] = useState<(() => Promise<any>) | undefined>();
  const [handleClose, setHandleClose] = useState<(() => Promise<any>) | undefined>();

  useEffect(() => {
    const preselectedNode = referenceableNodes.find((node) => node.id === nodeConfig.parameters.referencedNodeId);

    setSelectedNode(preselectedNode);
  }, [nodeConfig.parameters.referencedNodeId, referenceableNodes]);

  const confirmSwitchDataSource = (
    onConfirm: (() => Promise<void>) | undefined,
    onClose: (() => Promise<void>) | undefined
  ): Promise<void> =>
    new Promise((resolve) => {
      setSwitchDataSourceOpen(true);
      const handleConfirmWrapper = async () => {
        setSwitchDataSourceOpen(false);
        if (onConfirm) {
          await onConfirm();
        }

        resolve();
      };

      const handleCloseWrapper = async () => {
        setSwitchDataSourceOpen(false);
        if (onClose) {
          await onClose();
        }
        resolve();
      };

      setHandleConfirm(() => handleConfirmWrapper);
      setHandleClose(() => handleCloseWrapper);
    });
  const {
    palette: { primary },
  } = useTheme();
  return (
    <Stack px={2} justifyContent="center" gap={2} mt={1}>
      <Card>
        <CardHeader
          avatar={<FilterAltIcon sx={{ color: primary.main, fontSize: 32, ml: 0.5 }} />}
          subheader={cloudflowTexts.PICK_STEP_TO_FILTER}
          title={cloudflowTexts.FILTER_DATA}
          titleTypographyProps={{ variant: "body2", textDecoration: "none" }}
          subheaderTypographyProps={{ variant: "caption" }}
          sx={{ ".MuiCardHeader-action": { alignSelf: "center" }, p: 1, pr: 2 }}
        />
      </Card>
      <FieldReferenceForm
        selectedNode={selectedNode}
        confirmSwitchDataSource={confirmSwitchDataSource}
        referenceableNodes={referenceableNodes}
        setSelectedNode={setSelectedNode}
        texts={{
          datasource: {
            title: cloudflowTexts.SELECT_DATA_SOURCE,
            subtitle: cloudflowTexts.PICK_STEP_TO_FILTER,
            label: cloudflowTexts.DATA_SOURCES,
          },
          referenceField: {
            title: cloudflowTexts.SELECT_FIELD_TO_FILTER,
            subtitle: cloudflowTexts.FROM_CHOSEN_STEP,
            label: cloudflowTexts.REFERENCED_FIELD,
            placeholderTooltip: cloudflowTexts.SELECT_VALUE_FROM_DATA_SOURCE,
          },
        }}
      />
      <ConditionForm
        selectedNode={selectedNode}
        referenceableNodes={referenceableNodes}
        texts={{
          addNewGroupText: cloudflowTexts.ADD_NEW_FILTER_GROUP,
          defineCriteriaText: cloudflowTexts.DEFINE_FILTER_CRITERIA,
          fromChosenFieldText: cloudflowTexts.FROM_CHOSEN_STEP,
          groupText: cloudflowTexts.FILTER_GROUP,
          addNewText: cloudflowTexts.ADD_NEW_FILTER,
        }}
      />
      <SwitchDataSourceDialog
        isDialogOpened={switchDataSourceOpen}
        handleClose={handleClose}
        handleConfirm={handleConfirm}
      />
    </Stack>
  );
};

export default FilterParametersTab;
