import { useCallback, useMemo } from "react";

import { useHistory, useParams } from "react-router-dom";
import {
  AssetTypeDoiTCloudNavigator,
  AssetTypeDoiTCloudSolve,
  AssetTypeDoiTCloudSolveSingleAccelerator,
  type ProductTypes,
} from "@doitintl/cmp-models";
import { Container, Skeleton, Stack } from "@mui/material";

import { BottomAppBar } from "../../../Components/BottomAppBar";
import { Guard } from "../../../Components/Guard";
import { useAuthContext } from "../../../Context/AuthContext";
import { AccountManagersHooks } from "../../../Context/customer/AccountManagers";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useUserContext } from "../../../Context/UserContext";
import { useCustomerContract } from "../hooks";
import ContractPageAws from "./ContractPageAws";
import ContractPageDci from "./ContractPageDci";
import ContractPageGcp from "./ContractPageGcp";
import ContractPageGsuite from "./ContractPageGsuite";
import { ContractPagePlps } from "./ContractPagePlps";
import ContractPageSubscription from "./ContractPageSubscription";

type Props = {
  productTypes: ProductTypes;
};

const ContractPage = ({ productTypes }: Props) => {
  const history = useHistory();
  const { customer } = useCustomerContext();
  const { contractId } = useParams<{ contractId: string }>();
  const { userModel } = useUserContext();
  const { isDoitEmployee } = useAuthContext();
  const customerId = customer.id;
  const [accountManagers] = AccountManagersHooks.useAllDoersAccountManagers();
  const { contract, loading } = useCustomerContract(contractId);

  const accountManagerName = useMemo<string | undefined>(() => {
    if (!accountManagers) {
      return;
    }

    return accountManagers.find((am) => am.id === contract?.accountManager?.id)?.name;
  }, [accountManagers, contract?.accountManager?.id]);

  const permitted = useMemo<boolean>(() => {
    if (!loading && !contract) {
      // Loading's done with no yielded return - time to return 404.
      return false;
    }
    if (isDoitEmployee) {
      return true;
    }

    const userCustomerId = userModel?.customer?.ref.id;
    const userMatchesUrlCustomer = userCustomerId === customerId;
    const userMatchesContractCustomer = userCustomerId === contract?.customer.id;

    return userMatchesUrlCustomer && userMatchesContractCustomer;
  }, [contract, customerId, isDoitEmployee, loading, userModel?.customer?.ref.id]);

  const buildPage = useCallback(() => {
    if (!contract?.type) {
      return null;
    }

    switch (contract.type) {
      case "amazon-web-services":
      case "amazon-web-services-standalone":
      case "amazon-web-services-ppa-cloudfront":
      case "saas-console-aws":
        return <ContractPageAws contractId={contractId} contract={contract} accountManagerName={accountManagerName} />;
      case "google-cloud":
      case "google-cloud-standalone":
      case "looker":
      case "saas-console-gcp":
        return (
          <ContractPageGcp
            contract={contract}
            accountManagerName={accountManagerName}
            contractId={contractId}
            productTypes={productTypes}
          />
        );
      case AssetTypeDoiTCloudNavigator:
      case AssetTypeDoiTCloudSolve:
      case AssetTypeDoiTCloudSolveSingleAccelerator:
        return (
          <ContractPageSubscription
            contract={contract}
            accountManagerName={accountManagerName}
            productTypes={productTypes}
          />
        );
      case "g-suite":
      case "microsoft-azure":
      case "office-365":
        return (
          <ContractPageGsuite contract={contract} accountManagerName={accountManagerName} contractId={contractId} />
        );
      case "google-cloud-partner-led-premium-support":
        return <ContractPagePlps contract={contract} accountManagerName={accountManagerName} />;
      case "doit-cloud-intelligence":
        return <ContractPageDci contract={contract} accountManagerName={accountManagerName} />;
      default:
        return (
          <ContractPageGcp
            contract={contract}
            accountManagerName={accountManagerName}
            contractId={contractId}
            productTypes={productTypes}
          />
        );
    }
  }, [accountManagerName, contract, contractId, productTypes]);

  const handleBack = useCallback<() => void>(() => {
    history.push(`/customers/${customerId}/contracts/contracts-list`);
  }, [customerId, history]);

  const loadingPlaceholder = (
    <Stack spacing={6}>
      <Stack spacing={2}>
        <Skeleton variant="text" height="2rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
      </Stack>
      <Stack spacing={2}>
        <Skeleton variant="text" height="2rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
        <Skeleton variant="text" height="1rem" />
      </Stack>
    </Stack>
  );

  return (
    <Container maxWidth="sm" sx={{ pt: "3rem", pb: "6rem" }}>
      {loading ? (
        <>
          {loadingPlaceholder}
          <BottomAppBar
            disabled={false}
            handlePrimaryButtonClicked={handleBack}
            primaryButtonName="Close"
            maxWidth="sm"
          />
        </>
      ) : (
        <Guard isAllowed={permitted}>
          {buildPage()}
          <BottomAppBar
            disabled={false}
            handlePrimaryButtonClicked={handleBack}
            primaryButtonName="Close"
            maxWidth="sm"
          />
        </Guard>
      )}
    </Container>
  );
};

export default ContractPage;
