import { type ContractStateType } from "../../types";

export const validateDciSubscriptionStep = (state: ContractStateType, errors: Record<string, any>): boolean => {
  if (!state.tierId) {
    errors.tier = true;

    return true;
  }

  const fields = [
    "isCommitment",
    "commitmentMonths",
    "startDate",
    "endDate",
    "minimumServiceFee",
    "cloudSpendPercentage",
    "paymentTerm",
  ];
  if (state.tier?.name === "essentials") {
    errors.isCommitment = state.isCommitment;
    errors.commitmentMonths = !!state.commitmentMonths;
    errors.endDate = !!state.endDate;
    errors.minimumServiceFee = state.minimumServiceFee !== undefined;
    errors.cloudSpendPercentage = state.cloudSpendPercentage !== undefined;
    errors.paymentTerm = state.paymentTerm !== undefined;

    return fields.some((field) => errors[field]);
  }

  errors.isCommitment = state.isCommitment === undefined;
  errors.commitmentMonths =
    (state.isCommitment && !state.commitmentMonths) || (!state.isCommitment && state.commitmentMonths);
  errors.startDate = !state.startDate;
  errors.endDate = state.isCommitment && (!state.endDate || state.endDate < state.startDate);
  errors.minimumServiceFee = (state.minimumServiceFee as any) === "";
  errors.cloudSpendPercentage = (state.cloudSpendPercentage as any) === "";

  errors.paymentTerm = !state.paymentTerm;
  if (state.paymentTerm === "annual" && state.cloudSpendPercentage !== 0) {
    errors.cloudSpendPercentage = true;
  }

  return fields.some((field) => errors[field]);
};
