import { useEffect, useMemo, useState } from "react";

import { AssetTypeDoiTCloudIntelligence, AssetTypeLooker, type ProductTypes } from "@doitintl/cmp-models";
import { Autocomplete, Button, Container, DialogContent, Grid, TextField, Tooltip, Typography } from "@mui/material";

import { globalText } from "../../../../assets/texts";
import EntitySelect from "../../../../Components/Selects/EntitySelect";
import SimpleDialog from "../../../../Components/SimpleDialog";
import { isProduction } from "../../../../constants";
import { AccountManagersHooks } from "../../../../Context/customer/AccountManagers";
import { useContractsContext } from "../../../../Context/customer/ContractsContext";
import { type Assets } from "../../../../types/index";
import { isNext10Contract } from "../../utils";
import { type ContractStateType } from "../types";
import { getAssetsForCurrentContractType, ifProductWithoutEntity } from "../utils";
import AssetSelector from "./components/AssetSelector";
import { LookerSalesProcessField } from "./components/LookerSalesProcessField";
import PointOfSaleSelect from "./components/PointOfSaleSelect";

type Props = {
  productTypes: ProductTypes;
  assets: Assets;
  state: ContractStateType;
  handleChangeContractType: (event: any, selectedValue: { value: string } | null) => void;
  handleChange: (name: string) => ({ target }: { target: any }) => void;
  handleChangeAccountManager: (_: any, selectedValue: { value: string } | null) => void;
  handleChangeNumber: (name: string) => ({ target }: { target: any }) => void;
  updateContractStatus: (active: boolean, terminated: boolean) => Promise<void>;
  isDoitContractAdmin: boolean;
  isDoitContractOwner: boolean;
  handleAssetsChange: (event: any, value: any) => void;
  cancelContract: () => void;
  deleteContract: () => void;
};

export const GeneralStep = ({
  productTypes,
  assets,
  state,
  handleChangeContractType,
  handleChange,
  handleChangeAccountManager,
  handleChangeNumber,
  updateContractStatus,
  isDoitContractAdmin,
  isDoitContractOwner,
  handleAssetsChange,
  cancelContract,
  deleteContract,
}: Props) => {
  const [showCancelContractDialog, setShowCancelContractDialog] = useState(false);
  const [showTerminateContractDialog, setShowTerminateContractDialog] = useState(false);
  const [showDeleteContractDialog, setShowDeleteContractDialog] = useState(false);
  const [accountManagers] = AccountManagersHooks.useAllDoersAccountManagers();

  const toggleShowCancelContractDialog = () => {
    setShowCancelContractDialog(!showCancelContractDialog);
  };

  const toggleShowTerminateContractDialog = () => {
    setShowTerminateContractDialog(!showTerminateContractDialog);
  };

  const toggleShowDeleteContractDialog = () => {
    setShowDeleteContractDialog(!showDeleteContractDialog);
  };

  const assetsForType = useMemo(() => getAssetsForCurrentContractType(state, assets), [state, assets]);

  const showCancelContractButton =
    state.editMode &&
    state.active &&
    ((isNext10Contract(state.type) && (isDoitContractAdmin || !isProduction)) ||
      (!isNext10Contract(state.type) && isDoitContractAdmin) ||
      isDoitContractOwner);

  const showDeleteContractButton = state.editMode && isDoitContractOwner;

  const showUndoContractCancellationButton =
    state.editMode &&
    !state.active &&
    !state.terminated &&
    !state.isEditForbidden &&
    !isNext10Contract(state.type) &&
    state.type !== AssetTypeDoiTCloudIntelligence;

  const showTerminateContractButton =
    state.editMode &&
    state.active &&
    (isDoitContractAdmin || isDoitContractOwner) &&
    !isNext10Contract(state.type) &&
    state.type !== AssetTypeDoiTCloudIntelligence;

  const showUndoContractTerminationButton =
    state.editMode &&
    !state.active &&
    state.terminated &&
    (isDoitContractAdmin || isDoitContractOwner) &&
    !isNext10Contract(state.type);

  const accountManagersOptions = useMemo(
    () =>
      accountManagers
        ?.map((accountManager) => ({
          label: accountManager.name,
          value: accountManager.id,
        }))
        .sort((a, b) =>
          (a.label.split(" ")[1] ?? a.label.split(" ")[0]).localeCompare(b.label.split(" ")[1] ?? b.label.split(" ")[0])
        ),
    [accountManagers]
  );
  const { contracts } = useContractsContext();
  const hasValidLookerContracts = useMemo(() => {
    const filteredLookerContracts = contracts.filter((contract) => contract.type === AssetTypeLooker);

    return filteredLookerContracts.some((contract) => {
      const currentDate = new Date();
      const contractStartDate = contract?.startDate?.toDate();

      if (contractStartDate) {
        const longestSkuMonths = contract.properties?.skus?.reduce(
          (longest, sku) => (sku.months > longest ? sku.months : longest),
          0
        );

        if (longestSkuMonths === undefined) {
          return false;
        }
        const contractEndDate = new Date(contractStartDate);
        contractEndDate.setMonth(contractEndDate.getMonth() + longestSkuMonths);

        currentDate.setHours(0, 0, 0, 0);
        contractEndDate.setHours(0, 0, 0, 0);

        return contractEndDate > currentDate;
      }

      return false;
    });
  }, [contracts]);

  useEffect(() => {
    if (state.type === AssetTypeDoiTCloudIntelligence && state.pointOfSale !== "doit") {
      handleChange("entity")({ target: { value: undefined } });
    }
  }, [handleChange, state.pointOfSale, state.type]);

  if (!accountManagersOptions || !accountManagers) {
    return null;
  }

  return (
    <Container maxWidth="sm" data-cy="general-step">
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Autocomplete
            id="contract-type"
            data-cy="contract-type"
            blurOnSelect
            getOptionDisabled={(option) =>
              option.value === AssetTypeLooker &&
              hasValidLookerContracts &&
              !isDoitContractAdmin &&
              !isDoitContractOwner
            }
            options={productTypes}
            value={productTypes?.find((o) => o.value === state.type) ?? null}
            onChange={handleChangeContractType}
            isOptionEqualToValue={(o, v) => o.value === v.value}
            disabled={state.editMode}
            getOptionLabel={(option) =>
              option.value === AssetTypeLooker && hasValidLookerContracts
                ? `${option.label} (Contract already exists, Add SKUs to existing contract)`
                : option.label
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product type"
                helperText="Select the contract's product type"
                error={state.errors.type}
                fullWidth
              />
            )}
          />
        </Grid>
        {state.type === AssetTypeDoiTCloudIntelligence && (
          <Grid item xs={12}>
            <PointOfSaleSelect
              value={state.pointOfSale}
              onChange={handleChange("pointOfSale")}
              margin="dense"
              fullWidth
            />
          </Grid>
        )}
        {(!ifProductWithoutEntity(state.type, state.pointOfSale) || state.type === AssetTypeDoiTCloudIntelligence) && (
          <Grid item xs={12}>
            <EntitySelect
              fullWidth
              margin="dense"
              value={state.entity}
              onChange={handleChange("entity")}
              helperText="Select billing profile"
              error={state.errors.entity}
              disabled={
                (state.type === AssetTypeDoiTCloudIntelligence && state.pointOfSale !== "doit") || state.isEditForbidden
              }
            />
          </Grid>
        )}
        {state.type === AssetTypeLooker && (
          <Grid item xs={12}>
            <AssetSelector
              assets={assetsForType}
              value={state.assets[0]}
              onChange={handleAssetsChange}
              disabled={state.isEditForbidden}
              TextFieldProps={{
                label: "Google Cloud billing account",
                error: state.errors?.assets,
                helperText: "Restrict contract to an asset",
              }}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Autocomplete
            id="account-manager"
            blurOnSelect
            options={accountManagersOptions}
            value={
              accountManagersOptions.find((accountManager) => accountManager.value === state.accountManager) ?? null
            }
            onChange={handleChangeAccountManager}
            isOptionEqualToValue={(o, v) => o.value === v.value}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Account Manager"
                helperText="Select account manager for this contract"
                margin="dense"
                error={state.errors.accountManager}
                fullWidth
              />
            )}
            disabled={state.isEditForbidden}
          />
        </Grid>
        <Grid item xs={12}>
          {state.type === AssetTypeLooker && (
            <LookerSalesProcessField
              state={state}
              handleChange={handleChange}
              handleChangeNumber={handleChangeNumber}
            />
          )}
        </Grid>
        {showCancelContractButton && (
          <Grid item>
            <Button
              variant="outlined"
              color="error"
              onClick={toggleShowCancelContractDialog}
              data-cy="cancel-contract-button"
            >
              Cancel contract
            </Button>
            <SimpleDialog
              open={showCancelContractDialog}
              title="Cancel contract"
              onConfirm={
                isNext10Contract(state.type) || state.type === AssetTypeDoiTCloudIntelligence
                  ? cancelContract
                  : () => updateContractStatus(false, false)
              }
              onCancel={toggleShowCancelContractDialog}
              confirmButtonText="Cancel contract"
              confirmButtonColor="error"
              cancelButtonText={globalText.CLOSE}
            >
              <DialogContent>
                <Typography mt={3}>Are you sure you want to cancel this contract?</Typography>
              </DialogContent>
            </SimpleDialog>
          </Grid>
        )}
        {showUndoContractCancellationButton && (
          <Grid item>
            <Button
              data-cy="undo-cancel-contract-button"
              variant="outlined"
              color="primary"
              onClick={() => updateContractStatus(true, false)}
            >
              Undo cancellation
            </Button>
          </Grid>
        )}

        {showTerminateContractButton && (
          <Grid item>
            <Tooltip title="To be used when a customer offboards from DoiT">
              <Button
                variant="outlined"
                color="error"
                onClick={toggleShowTerminateContractDialog}
                data-cy="terminate-contract-button"
              >
                Terminate contract
              </Button>
            </Tooltip>
            <SimpleDialog
              open={showTerminateContractDialog}
              title="Terminate contract"
              onConfirm={() => updateContractStatus(false, true)}
              onCancel={toggleShowTerminateContractDialog}
              confirmButtonText="Terminate contract"
              confirmButtonColor="error"
            >
              <DialogContent>
                <Typography mt={3}>Are you sure you want to terminate this contract of offboarded customer?</Typography>
              </DialogContent>
            </SimpleDialog>
          </Grid>
        )}

        {showUndoContractTerminationButton && (
          <Grid item>
            <Button
              data-cy="undo-terminate-contract-button"
              variant="outlined"
              color="primary"
              onClick={() => updateContractStatus(true, false)}
            >
              Undo Termination
            </Button>
          </Grid>
        )}

        {showDeleteContractButton && (
          <Grid item>
            <Button
              variant="outlined"
              color="error"
              onClick={toggleShowDeleteContractDialog}
              data-cy="delete-contract-button"
            >
              Delete contract
            </Button>
            <SimpleDialog
              open={showDeleteContractDialog}
              title="Delete contract"
              onConfirm={deleteContract}
              onCancel={toggleShowDeleteContractDialog}
              confirmButtonText="Delete contract"
              confirmButtonColor="error"
              cancelButtonText={globalText.CLOSE}
            >
              <DialogContent>
                <Typography mt={3}>Are you sure you want to delete this contract?</Typography>
              </DialogContent>
            </SimpleDialog>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};
