import { useMemo } from "react";

import { CloudFlowNodeType } from "@doitintl/cmp-models";

import { type ConfigurationTab, type NodeConfigs } from "../../../types";

export const useTabs = (nodeConfig: NodeConfigs) =>
  useMemo(() => {
    const tabs: ConfigurationTab[] = [];

    switch (nodeConfig.type) {
      case CloudFlowNodeType.ACTION:
        if (nodeConfig.parameters) tabs.push("Parameters");
        tabs.push("Permissions", "Test");
        break;
      case CloudFlowNodeType.TRIGGER:
        tabs.push("Schedule");
        break;
      case CloudFlowNodeType.FILTER:
        tabs.push("FilterParameters");
        break;
      case CloudFlowNodeType.MANUAL_TRIGGER:
        tabs.push("ManualTrigger");
        break;
      default:
        if (nodeConfig.parameters) tabs.push("Parameters");
        break;
    }

    return tabs;
  }, [nodeConfig.type, nodeConfig.parameters]);
