import { type ContractStateType } from "../../types";

export const validateAcceleratorDetailsStep = (state: ContractStateType, errors: Record<string, any>): boolean => {
  const fields = ["typeContext", "startDate", "endDate", "chargePerTerm", "estimatedFunding"];

  errors.typeContext = !state.typeContext;
  errors.startDate = !state.startDate;
  errors.endDate = !state.endDate || state.endDate < state.startDate;
  errors.chargePerTerm = !state.chargePerTerm;
  errors.estimatedFunding = state.showEstimatedFunding && !state.estimatedFunding;

  return fields.some((field) => errors[field]);
};
