import { collection, type Reference, type Timestamp } from "@doitintl/models-types";

import { type CurrencyCode } from "./Currency";

type ObjectValues<T> = T[keyof T];

export type EntitlementLimitType = number | string | Array<string>;

export const TierTypes = {
  INTERNAL: "internal",
  PRESET: "preset",
  CUSTOM: "custom",
} as const;

export type TierType = ObjectValues<typeof TierTypes>;

export const TierPackageTypes = {
  NAVIGATOR: "navigator",
  SOLVE: "solve",
} as const;

export type TierPackageType = ObjectValues<typeof TierPackageTypes>;

const ENTITLEMENT_KEYS = [
  "analytics:reports",
  "analytics:attributions",
  "analytics:attributionGroups",
  "analytics:alerts",
  "analytics:labels",
  "analytics:reports:presets",
  "analytics:reports:templates",
  "analytics:attributions:presets",
  "analytics:attributionGroups:presets",
  "analytics:calculatedMetrics",
  "analytics:calculatedMetrics:presets",
  "analytics:forecasts",
  "analytics:advanced",
  "analytics:extendedMetrics:amortizedCostSavings",

  "dashboard:home",
  "dashboard:pulse",
  "dashboard:custom",

  "governance:alerts",
  "governance:budgets",
  "governance:costAnomalies",
  "governance:costAnomalies:acknowledgement",
  "governance:insights",
  "governance:cloudIncidents",
  "governance:sandboxes",
  "governance:threads",

  "lens:aws",
  "lens:gcp",
  "lens:azure",
  "lens:gke",
  "lens:eks",
  "lens:eks-new",
  "lens:bigquery",
  "lens:bigquery:editions",

  "notification:email",
  "notification:digest:daily",
  "notification:digest:weekly",
  "notification:digest:monthly",
  "notification:quotaAlerts",
  "notification:slack:shared",
  "notification:slack:app",

  "pdi:dataRetention",
  "pdi:gcp",
  "pdi:aws",
  "pdi:azure",
  "pdi:gke:costAllocation",
  "pdi:eks",
  "pdi:datahub",

  "platform:ava",
  "platform:ava:reports",
  "platform:ava:icon",
  "platform:users",
  "platform:multiUserAdminAccess",
  "platform:IAMOrg",
  "platform:terraform",
  "platform:zapier",
  "platform:graphana",
  "platform:externalApi",
  "platform:audit:logs",
  "platform:auth:usernamePassword",
  "platform:auth:sso:googleMicrosoft",
  "platform:auth:sso:samloidc",
  "platform:dataExport",

  "savings:flexsaveAWS",
  "savings:spotScaling",

  "services:accelerator",
  "services:accelerator:included",
  "services:accelerator:included:4",
  "services:accelerator:paid",
  "services:pro-serv",
  "services:training",
  "services:workshop",
  "services:workshop:included",
  "services:workshop:paid",

  "snowflake:connect",

  "support:ticket:call-check",
  "support:ticket:chat-with-ava",
  "support:ticket:menu:platform:all",
  "support:ticket:menu:platform:cloud_management_platform",
  "support:ticket:menu:platform:finance-billing",
  "support:ticket:menu:platform:amazon-web-services",
  "support:ticket:menu:platform:google-cloud",
  "support:ticket:menu:platform:g-suite",
  "support:ticket:menu:platform:promotional-credits",
  "support:ticket:menu:platform:microsoft-azure",
  "support:ticket:menu:platform:office-365",
  "support:ticket:menu:product:all",
  "support:ticket:menu:product:all:platform:cloud-management-platform",
  "support:ticket:menu:product:all:platform:finance",
  "support:ticket:menu:product:all:platform:credits",
  "support:ticket:menu:product:all:platform:office-365",
  "support:ticket:menu:product:all:platform:g-suite",
  "support:ticket:menu:product:aws/amazon-ec2",
  "support:ticket:menu:product:aws/amazon-elastic-block-store-ebs",
  "support:ticket:menu:product:aws/amazon-s3",
  "support:ticket:menu:product:aws/elastic-load-balancing-elb",
  "support:ticket:menu:product:gcp/cloud-load-balancing",
  "support:ticket:menu:product:gcp/cloud-storage",
  "support:ticket:menu:product:gcp/compute-engine",
  "support:ticket:menu:product:gcp/persistent-disk",
  "support:ticket:menu:product:gcp/google-workspace",
  "support:ticket:menu:product:gcp/google-maps-platform",
  "support:ticket:menu:product:gcp/looker",
  "support:ticket:menu:product:azure/virtual-machines",
  "support:ticket:menu:product:azure/azure-load-balancing",
  "support:ticket:menu:product:azure/storage-blobs",
  "support:ticket:menu:product:azure/storage-disks",
] as const;
export type EntitlementKey = (typeof ENTITLEMENT_KEYS)[number];
export const isEntitlementKey = (key: string): key is EntitlementKey => {
  const entitlementKeysSet = new Set(ENTITLEMENT_KEYS);
  return entitlementKeysSet.has(key as EntitlementKey);
};

export type EntitlementsAccess = Partial<Record<EntitlementKey, boolean>>;

export const NavigatorTierPackageSKUs = {
  STANDARD: "P-ST-M-D-001",
  ENHANCED: "P-ET-M-D-001",
  PREMIUM: "P-PT-M-D-001",
  ENTERPRISE: "P-EP-M-D-001",
} as const;

export const SolveTierPackageSKUs = {
  TRIAL: "S-TR-M-D-001",
  LEGACY_RESOLD: "S-LR-M-D-001",
  ADVANTAGE_ONLY: "S-AD-M-D-001",

  STANDARD_BASE: "S-ST-M-D-001",
  STANDARD_VARIABLE: "S-ST-M-D-002",
  ENHANCED_BASE: "S-ET-M-D-001",
  ENHANCED_VARIABLE: "S-ET-M-D-002",
  PREMIUM_BASE: "S-PT-M-D-001",
  PREMIUM_VARIABLE: "S-PT-M-D-002",
  ENTERPRISE_BASE: "S-EP-M-D-001",
  ENTERPRISE_VARIABLE: "S-EP-M-D-002",

  SINGLE_TICKET: "S-STP-M-D001",
  SINGLE_WORKSHOP: "S-SWS-M-D001",
  SINGLE_ACCELERATOR: "S-SAC-M-D001",
  SINGLE_TRAINING: "S-STR-M-D001",
} as const;

export const InternalTierNames = {
  PRESENTATION: "presentation",
  ZERO_ENTITLEMENTS: "zero-entitlements",
  HERIGATE: "last10",
  ESSENTIALS: "essentials",
} as const;

export type NavigatorSKUs = ObjectValues<typeof NavigatorTierPackageSKUs>;
export type SolveSKUs = ObjectValues<typeof SolveTierPackageSKUs>;

@collection("entitlements")
export class EntitlementModel {
  key!: EntitlementKey;

  type!: TierPackageType;

  displayName!: string;

  description!: string;

  limit?: EntitlementLimitType;
}

@collection("tiers")
export class TierModel {
  name!: string;

  sku!: NavigatorSKUs | SolveSKUs;

  displayName!: string;

  description!: string;

  entitlements!: Reference<EntitlementModel>[];

  packageType!: TierPackageType;

  type!: TierType;

  timeCreated!: Timestamp;

  timestamp!: Timestamp;

  price!: Record<CurrencyCode, number>;

  trialTier?: boolean;
}
