import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import { SyncLoader } from "react-spinners";
import { AvaOpeningSource, useAvaDialogContext } from "@doitintl/ava-components/src/Common/AvaDialogContextProvider";
import { DashboardType, UserNotification } from "@doitintl/cmp-models";
import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import { useApiContext } from "../../api/context";
import { dashboardsTxt } from "../../assets/texts/Customer/dashboards";
import ProcessingState from "../../Components/Dashboard/Common/ProcessingState/ProcessingState";
import { AddDashboardType, CreateDashboardDialog } from "../../Components/Dashboard/CreateDashboardDialog";
import { DashboardGuard } from "../../Components/Dashboard/DashboardGuard";
import { isHomeDashboard, useDashboardNavigation } from "../../Components/Dashboard/DashboardNavigartionContext";
import {
  areAllWidgetsEmpty,
  excludeWidgetsByName,
  filterWidgetsByName,
  getDashboardType,
  isDashboardLoading,
} from "../../Components/Dashboard/utils/widgetUtils";
import { WidgetsList } from "../../Components/Dashboard/WidgetsList";
import DeleteDialog from "../../Components/DeleteDialog";
import { DoitConsoleTitle } from "../../Components/DoitConsoleTitle";
import { useDashboardsMetadata } from "../../Components/hooks/cloudAnalytics/useDashboardsMetadata";
import { useCustomerId } from "../../Components/hooks/useCustomerId";
import { useDashboardWidgetDataRefresh } from "../../Components/hooks/useDashboardWidgetDataRefresh";
import NoEntitlement from "../../Components/NoEntitlement/NoEntitlement";
import { useDashboardSubscriptions } from "../../Components/NotificationSubscription/db";
import { SubscribeButton } from "../../Components/NotificationSubscription/SubscribeButton";
import { useInfoSnackbar, useSnackbar } from "../../Components/SharedSnackbar/SharedSnackbar.context";
import SplitButton from "../../Components/SplitButton";
import { ThreeDotsMenu, type ThreeDotsMenuOption } from "../../Components/ThreeDotsMenu";
import { isProduction } from "../../constants";
import { useAuthContext } from "../../Context/AuthContext";
import { useCurrentDashboardContext, type Widget } from "../../Context/CurrentDashboardContext";
import { useCustomerContext } from "../../Context/CustomerContext";
import { useDashboardsContext } from "../../Context/DashboardContext";
import { useDashboardProcessingContext } from "../../Context/DashboardProcessingContext";
import { useIsFeatureEntitled, useTier } from "../../Context/TierProvider";
import { useCloudReportsWidgets } from "../../Context/useCloudReportsWidgets";
import { isCustomerInPresentationMode } from "../../Context/useCustomerOrPresentationModeCustomer";
import { useUserContext } from "../../Context/UserContext";
import PhoneDialog from "../../Support/Components/PhoneDialog";
import { consoleErrorWithSentry } from "../../utils";
import { getDashboardNameById, getUserOrgID, GlobalDashboardId } from "../../utils/common";
import mixpanel from "../../utils/mixpanel";
import { getWidgetId } from "../../utils/widgets";
import { useFormatLink } from "../CloudAnalytics/analyticsResources/hooks";
import { useNewReportHandler } from "../CloudAnalytics/report/hooks";
import DashboardEmptyState from "./DashboardEmptyState";
import { updateVisibleDashboards } from "./db";
import { DashboardLayout } from "./NewDashboards/DashboardLayout";
import { DashboardSideMenu } from "./NewDashboards/DashboardSideMenu";
import { AddReportDialog } from "./NewDashboards/Dialogs/AddReport/AddReportDialog";
import { EmptyDashboard } from "./NewDashboards/EmptyDashboard";
import { type DashboardOperations } from "./NewDashboards/useDashboardTypes";
import { WidgetsGridPageWrapper } from "./NewDashboards/WidgetsGrid/WidgetsGridPageWrapper";
import DataImportBanner from "./SaaSConsoleOnboarding/DataImportBanner";

const useStyles = makeStyles(() => ({
  progress: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    flex: 1,
    height: "70vh",
    alignItems: "center",
  },
}));

const BQLensDashboardName = "BQ Lens";

export const CustomerDashboards = () => {
  const history = useHistory();
  const customerId = useCustomerId();
  const theme = useTheme();
  const classes = useStyles();
  const api = useApiContext();
  const { partnerCompany, isDoitPartner, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const isDoitPartnerOrDoitEmployee = (isDoitPartner && !!partnerCompany) || isDoitEmployee;
  const {
    entities,
    entitiesLoading,
    customer: originalCustomer,
    customerOrPresentationModeCustomer: customer,
    userOrganization,
    isProductOnlyCustomer,
  } = useCustomerContext();
  const { getFeatureName } = useTier();
  const { userRoles } = useUserContext();

  const isEntitledDashboardCustom = useIsFeatureEntitled("dashboard:custom");
  const isEntitledAvaIcon = useIsFeatureEntitled("platform:ava:icon");

  // dashboard hooks
  const { dashboards, defaultDashboard, setDefaultDashboard, deleteDashboardByName, detachDashboardByName } =
    useDashboardsContext();
  const { historyGotoDashboard } = useDashboardNavigation();
  const { changeDashboardByName, currentDashboard } = useCurrentDashboardContext();

  const widgets = useMemo(() => currentDashboard?.widgets ?? [], [currentDashboard?.widgets]);

  const { dashboardWidgetStates, setRefreshTriggerTime } = useDashboardProcessingContext();
  const [handleDashboardMetadataUpdate] = useDashboardsMetadata();
  const [currentDashboardOperations, setCurrentDashboardOperations] = useState<DashboardOperations>({});

  // widgets hooks
  const [cloudReportsWidgets, loadingCloudReportWidgets] = useCloudReportsWidgets();

  // inner state
  const [widgetLibraryOpen, setWidgetLibraryOpen] = useState(false);
  const [addDashboardType, setAddDashboardType] = useState<AddDashboardType>();
  const { name } = useParams<{ name: string }>();
  const [isPhoneOpen, setIsPhoneOpen] = useState(false);
  const [isCreateDashboardDialogOpen, setIsCreateDashboardDialogOpen] = useState(false);
  const [hasCloudReportWidgets, setHasCloudReportWidgets] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openAddReportDialog, setOpenAddReportDialog] = useState(false);

  // components
  const sharedSnackbar = useSnackbar();
  const showInfoSnackbar = useInfoSnackbar();

  const { handleOpenAvaDialog } = useAvaDialogContext();
  const reportLink = useFormatLink({ type: "report" });
  const handleNewReport = useNewReportHandler({
    baseUrl: reportLink,
    mixpanelEventName: "analytics.reports.new",
    additionalState: {
      dashboardId: currentDashboard?.id,
      dashboardName: currentDashboard?.name,
    },
  });

  const emptyDashboard = widgets.length === 0;

  const subscriptions = useDashboardSubscriptions({ dashboardPath: currentDashboard?.ref?.path ?? null });

  const notAllowedToAddReport = !isEntitledDashboardCustom || (!userRoles?.cloudAnalytics && !isDoitEmployee);

  const hasAccessToAva = isDoitEmployee || !!isEntitledAvaIcon;

  const updateUserDefaultDashboard = useCallback(async () => {
    if (!currentDashboard) {
      return;
    }

    mixpanel.track("dashboards.set-as-default");
    await setDefaultDashboard(currentDashboard.id);
    sharedSnackbar.onOpen({
      message: `'${isHomeDashboard(currentDashboard.name) ? "Account" : currentDashboard.name}' is set as your default dashboard`,
      variant: "success",
      autoHideDuration: 4000,
      action: [
        <IconButton key="close" aria-label="Close" color="inherit" onClick={sharedSnackbar.onClose} size="large">
          <CloseIcon />
        </IconButton>,
      ],
    });
  }, [currentDashboard, setDefaultDashboard, sharedSnackbar]);

  const disableDashboardCreation = Boolean(
    (userOrganization?.data.disableAccountDashboard && userOrganization.data.dashboards.length === 0) ||
      !isEntitledDashboardCustom
  );
  const shouldRefreshDashboardWidgets = useDashboardWidgetDataRefresh(
    customer.id,
    getUserOrgID(isDoitEmployee, userOrganization),
    currentDashboard?.id ?? ""
  );

  const isEksDashboard = currentDashboard?.id === GlobalDashboardId.EKSLens;

  useEffect(() => {
    if (isProduction && isDoitPartnerOrDoitEmployee && currentDashboard?.ref?.path && shouldRefreshDashboardWidgets) {
      updateVisibleDashboards(api, customer.id, [currentDashboard.ref.path]);
    }
  }, [currentDashboard, api, customer, isDoitPartnerOrDoitEmployee, shouldRefreshDashboardWidgets]);
  /**
   * Retrieves the list of widgets to be rendered based on the current dashboard and cloud report states.
   */
  const [widgetsToRender, noCloudReportsData] = useMemo((): [Widget[], boolean] => {
    if (!currentDashboard) {
      return [widgets, false];
    }
    const dashboardType = getDashboardType(name);

    // Filter widgets related to cloud reports
    const cloudReportWidgets = filterWidgetsByName(widgets, "cloudReports");
    const widgetStates = dashboardWidgetStates[currentDashboard.name] || {};

    // Check if all cloud reports are empty
    const areCloudReportsEmpty = areAllWidgetsEmpty(widgetStates);

    // Remove fixed widgets if no cloud report is available
    if (widgets.length > 0 && areCloudReportsEmpty) {
      if (dashboardType === DashboardType.AwsLens || dashboardType === DashboardType.SaaSAwsLens) {
        return [excludeWidgetsByName(widgets, "flexSaveAws"), areCloudReportsEmpty];
      }
    }

    // Continue using the original widget array until all cloud reports are processed
    if (widgets.length > 0 && Object.keys(widgetStates).length !== cloudReportWidgets.length) {
      return [widgets, areCloudReportsEmpty];
    }

    // Filter cloud report widgets based on the availability of data
    const filteredWidgets = widgets.filter((item) => {
      if (item.name.includes("cloudReports")) {
        const widgetId = getWidgetId(item);
        return widgetStates[widgetId]?.hasData && item?.state !== "failed";
      }
      return true;
    });

    return [filteredWidgets, areCloudReportsEmpty];
  }, [currentDashboard, dashboardWidgetStates, name, widgets]);

  const changeDashboardByNameAndUI = useCallback(
    (name: string | number) => {
      historyGotoDashboard(name);
    },
    [historyGotoDashboard]
  );

  const handleDashboardDelete = async () => {
    if (!currentDashboard) {
      return;
    }
    changeDashboardByNameAndUI(0);
    await deleteDashboardByName(currentDashboard.id);
    setOpenDeleteDialog(false);
  };

  const handleCreateReportUsingAva = useCallback(() => {
    handleOpenAvaDialog(AvaOpeningSource.DASHBOARDS, undefined, currentDashboard?.id);
  }, [currentDashboard?.id, handleOpenAvaDialog]);

  const changeDashboardByNameAndUIRef = useRef(changeDashboardByNameAndUI);

  useEffect(() => {
    changeDashboardByNameAndUIRef.current = changeDashboardByNameAndUI;
  });

  useEffect(() => {
    if (!defaultDashboard) {
      return;
    }

    if (!name) {
      changeDashboardByNameAndUI("");
    }
  }, [name, defaultDashboard, changeDashboardByNameAndUI]);

  useEffect(() => {
    if (!name || !defaultDashboard) {
      return;
    }

    if (name.localeCompare("default", undefined, { sensitivity: "base" }) === 0) {
      changeDashboardByNameAndUI(defaultDashboard ?? "Account");
      return;
    }

    changeDashboardByName(name).catch(consoleErrorWithSentry);
  }, [name, changeDashboardByName, changeDashboardByNameAndUI, defaultDashboard]);

  const optionsForDashboard = useMemo(() => {
    const isAccountDashboard = currentDashboard?.name === "Account";
    const options: ThreeDotsMenuOption[] = [
      {
        key: "edit-dashboard-settings",
        label: "Edit dashboard settings",
        action: () => {
          mixpanel.track("dashboards.settings");
          setAddDashboardType(AddDashboardType.Edit);
          setIsCreateDashboardDialogOpen(true);
        },
        disabled: !currentDashboardOperations.allowToEdit || isAccountDashboard,
      },
      {
        key: "set-default-dashboard",
        label: "Set as default",
        action: updateUserDefaultDashboard,
        disabled: defaultDashboard === currentDashboard?.name,
      },
    ];

    options.push({
      key: "refresh-all",
      label: "Refresh all widgets",
      action: async () => {
        setRefreshTriggerTime();
      },
    });

    if (!isAccountDashboard && currentDashboardOperations.allowToDelete) {
      options.push({
        key: "delete-dashboard",
        label: "Delete dashboard",
        action: () => {
          setOpenDeleteDialog(true);
        },
        color: "error.main",
      });
    }

    if (!isAccountDashboard && currentDashboardOperations.allowToRemove && currentDashboard?.id) {
      options.push({
        key: "remove-dashboard",
        label: "Remove dashboard",
        action: async () => {
          changeDashboardByNameAndUI(0);
          await detachDashboardByName(currentDashboard.id);
        },
      });
    }

    return options;
  }, [
    changeDashboardByNameAndUI,
    currentDashboard?.id,
    currentDashboard?.name,
    currentDashboardOperations.allowToDelete,
    currentDashboardOperations.allowToEdit,
    currentDashboardOperations.allowToRemove,
    defaultDashboard,
    detachDashboardByName,
    setRefreshTriggerTime,
    updateUserDefaultDashboard,
  ]);

  const processedWidgets = useMemo(
    () => (!currentDashboard ? {} : (dashboardWidgetStates?.[currentDashboard.name] ?? {})),
    [currentDashboard, dashboardWidgetStates]
  );

  const loading = isDashboardLoading(widgets, name, processedWidgets);

  const [updatedTimeLastAccessed, setUpdatedTimeLastAccessed] = useState<{ [key: string]: boolean }>({});

  const currentDashboardId = currentDashboard ? currentDashboard.id : "";

  useEffect(() => {
    if (isProduction && hasCloudReportWidgets && isDoitPartnerOrDoitEmployee && shouldRefreshDashboardWidgets) {
      showInfoSnackbar(dashboardsTxt.DASHBOARDS_ARE_BEING_UPDATED);
    }
  }, [
    hasCloudReportWidgets,
    showInfoSnackbar,
    isDoitPartnerOrDoitEmployee,
    currentDashboard?.name,
    shouldRefreshDashboardWidgets,
  ]);

  useEffect(() => {
    if (currentDashboardId !== "" && !updatedTimeLastAccessed[currentDashboardId] && !loading) {
      const cloudReportsWidgets = filterWidgetsByName(widgetsToRender, "cloudReports");

      const cloudReportsWidgetsWithData = cloudReportsWidgets.filter((item) => {
        const widgetId = getWidgetId(item);
        return processedWidgets[widgetId];
      });

      if (cloudReportsWidgetsWithData.length > 0) {
        setHasCloudReportWidgets(true);
        const orgId = getUserOrgID(isDoitEmployee, userOrganization);

        handleDashboardMetadataUpdate(customer.id, orgId, currentDashboardId);

        setUpdatedTimeLastAccessed((prevState) => ({
          ...prevState,
          [currentDashboardId]: true,
        }));
      }
    }
  }, [
    currentDashboardId,
    customer.id,
    handleDashboardMetadataUpdate,
    isDoitEmployee,
    loading,
    processedWidgets,
    updatedTimeLastAccessed,
    userOrganization,
    widgetsToRender,
  ]);

  if (dashboards.length === 0 || !currentDashboard?.id) {
    return (
      <div className={classes.progress}>
        <SyncLoader size={10} color={theme.palette.primary.main} loading />
      </div>
    );
  }

  if (
    !isCustomerInPresentationMode(originalCustomer) &&
    !isProductOnlyCustomer &&
    !entitiesLoading &&
    (entities.length === 0 || !entities.some((x) => x.active))
  ) {
    return <DashboardEmptyState />;
  }

  const isCustomDashboard =
    currentDashboard?.id !== GlobalDashboardId.Home &&
    getDashboardType(currentDashboard?.name) === currentDashboard?.name;

  // -2 restore the padding that the header will start from zero
  return (
    <Box mx={-2} mt={-1}>
      <DoitConsoleTitle pageName="Dashboard" pageLevel1={currentDashboard.name} />
      <Stack spacing={1}>
        <DashboardLayout
          menu={
            <DashboardSideMenu
              selectedDashboardId={currentDashboardId}
              onNavigateToDashboard={changeDashboardByNameAndUI}
              disableNewDashboardCreation={disableDashboardCreation}
              onUpdateCurrentDashboardOperations={setCurrentDashboardOperations}
              onNewDashboardClicked={() => {
                setAddDashboardType(AddDashboardType.Create);
                setIsCreateDashboardDialogOpen(true);
              }}
            />
          }
          title={
            <Typography variant="h1" py={1}>
              {getDashboardNameById(currentDashboard.id, currentDashboard.name)}
            </Typography>
          }
          widgetActions={
            <Stack spacing={2} direction="row" alignItems="center">
              {!emptyDashboard && currentDashboardOperations.allowToAddWidgets && (
                <SplitButton
                  options={[
                    {
                      label: "Add existing report",
                      action: () => {
                        setOpenAddReportDialog(true);
                      },
                      disabled: notAllowedToAddReport,
                    },
                    {
                      label: "Add widget",
                      action: () => {
                        setWidgetLibraryOpen(true);
                      },
                    },
                    {
                      label: "Create a new report",
                      action: handleNewReport,
                      disabled: notAllowedToAddReport,
                    },
                    ...(hasAccessToAva
                      ? [
                          {
                            label: "Create a report using Ava",
                            action: handleCreateReportUsingAva,
                            disabled: notAllowedToAddReport,
                          },
                        ]
                      : []),
                  ]}
                />
              )}
              {!emptyDashboard && (userRoles?.cloudAnalytics || isDoitEmployee) && (
                <SubscribeButton
                  subscriptions={subscriptions}
                  subscriptionType={UserNotification.DashboardSubscription}
                  subscriptionParent={currentDashboard}
                />
              )}
              {isEksDashboard && (
                <Button
                  variant="contained"
                  sx={{ width: "fit-content" }}
                  onClick={() => {
                    history.push(`/customers/${customerId}/eks-onboarding`);
                  }}
                >
                  See your list of clusters
                </Button>
              )}
            </Stack>
          }
          operations={<ThreeDotsMenu closeAfterSelect options={optionsForDashboard} />}
        >
          <Box>
            <DataImportBanner dashboardType={getDashboardType(name)} />
            {loading ? (
              <Grid item xs={12} mb={1} px={2}>
                <ProcessingState dashboardType={getDashboardType(name)} />
              </Grid>
            ) : (
              <Grid
                container
                spacing={1}
                pl={{
                  xs: 2,
                  sm: 1,
                }}
                pr={{
                  xs: 1,
                  sm: 0,
                }}
              >
                <DashboardGuard>
                  {isCustomDashboard &&
                  !isEntitledDashboardCustom &&
                  !(
                    isCustomerInPresentationMode(originalCustomer) && getDashboardType(name) === BQLensDashboardName
                  ) ? (
                    <NoEntitlement
                      feature={getFeatureName("dashboard:custom") ?? "this feature"}
                      inPresentationMode={originalCustomer.presentationMode?.enabled}
                    />
                  ) : emptyDashboard ? (
                    <EmptyDashboard
                      onAddExistingReportClicked={() => {
                        setOpenAddReportDialog(true);
                      }}
                      onNewReportClicked={handleNewReport}
                      onNewWidgetClicked={() => {
                        setWidgetLibraryOpen(true);
                      }}
                      onAvaClicked={handleCreateReportUsingAva}
                      showActions={Boolean(currentDashboardOperations?.allowToAddWidgets)}
                      showAva={hasAccessToAva}
                    />
                  ) : (
                    <WidgetsGridPageWrapper
                      showSummaryCards={currentDashboard.dashboardType === DashboardType.Pulse && !noCloudReportsData}
                      disableEditing={!currentDashboardOperations?.allowToCustomizeLayout}
                      widgets={widgetsToRender}
                    />
                  )}
                </DashboardGuard>
              </Grid>
            )}
          </Box>
        </DashboardLayout>
      </Stack>
      {!isDoitPartner && (
        <>
          {isPhoneOpen && (
            <PhoneDialog
              onClose={() => {
                setIsPhoneOpen(false);
              }}
            />
          )}
        </>
      )}
      {isCreateDashboardDialogOpen && addDashboardType && (
        <CreateDashboardDialog
          type={addDashboardType}
          selectedDashboardId={currentDashboardId}
          handleClose={(name) => {
            setIsCreateDashboardDialogOpen(false);
            if (name) {
              historyGotoDashboard(name);
            }
          }}
        />
      )}

      <DeleteDialog
        open={openDeleteDialog}
        title="Delete dashboard?"
        message="Are you sure you want to delete the dashboard?"
        onDelete={handleDashboardDelete}
        onClose={() => {
          setOpenDeleteDialog(false);
        }}
        deleteButtonText="Delete dashboard"
      />

      {!notAllowedToAddReport && openAddReportDialog && (
        <AddReportDialog
          open={openAddReportDialog}
          loading={loadingCloudReportWidgets}
          availableWidgets={cloudReportsWidgets}
          selectedWidgetNames={widgetsToRender.map((w) => w.name)}
          onClose={() => {
            setOpenAddReportDialog(false);
          }}
        />
      )}

      {widgetLibraryOpen && (
        <WidgetsList
          onClose={() => {
            setWidgetLibraryOpen(false);
          }}
        />
      )}
    </Box>
  );
};
