import { useEffect } from "react";

import { type CurrencyCode, type EntityModel } from "@doitintl/cmp-models";
import { type ModelId } from "@doitintl/models-firestore";
import { Button, MenuItem, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useFormikContext } from "formik";

import { BillCom } from "../../../../../Pages/Entity/PaymentMethods/BillCom";
import { StripeSetupSession } from "../../../../../Pages/Entity/PaymentMethods/Stripe";
import { WireTransfer } from "../../../../../Pages/Entity/PaymentMethods/WireTransfer";
import { useToggle } from "../../../../../utils/useToggle";
import { useSuccessSnackbar } from "../../../../SharedSnackbar/SharedSnackbar.context";
import { type EditBillingProfileData } from "../../../BillingProfileForm.models";
import { PaymentMethodCategory, type SavedPaymentMethod } from "../../api";
import { AddPaymentMethodDialog } from "./AddPaymentMethodDialog";
import { PaymentMethodSelector } from "./PaymentMethodSelector";

type PaymentMethodsPanelProps = {
  entity: ModelId<EntityModel>;
  savedPaymentMethods: SavedPaymentMethod[];
};

export const PaymentMethodsPanel = ({ entity, savedPaymentMethods }: PaymentMethodsPanelProps) => {
  const [isOpened, openPaymentMethodsModal, closePaymentMethodsModal] = useToggle(false);
  const showSuccessSnackbar = useSuccessSnackbar();
  const currentUrl = new URL(window.location.href);

  const getPaymentMethodModalButtonText = (currency: CurrencyCode) => {
    if (["EUR", "USD"].includes(currency)) {
      return "Add new payment method";
    }
    return "Add new payment card";
  };

  const getStripePaymentMethodItemText = (currency?: CurrencyCode | "") => {
    if (currency === "EGP") {
      return "Credit card";
    }

    return "Credit card or Bank account";
  };

  const {
    values: { paymentMethodId, paymentMethodCategory: paymentMethodCategory },
    setFieldValue,
    handleChange,
  } = useFormikContext<EditBillingProfileData>();

  useEffect(() => {
    const url = new URL(location.href);
    if (url.searchParams.has("setup_intent") || url.searchParams.has("checkout_session")) {
      setFieldValue("paymentMethodCategory", PaymentMethodCategory.STRIPE);
    }
    if (url.searchParams.get("redirect_status") === "succeeded") {
      showSuccessSnackbar("Payment method successfully added.");
    }
  }, [setFieldValue, showSuccessSnackbar]);

  const isWireTransferSupported = !!entity.currency && entity.currency !== "EGP";
  const isBillComSupported = !!entity.currency && entity.currency !== "EGP";

  return (
    <>
      <AddPaymentMethodDialog entity={entity} open={isOpened} onClose={closePaymentMethodsModal} />

      <Stack spacing={2} mt={2}>
        <Typography color="text.secondary">Payment method</Typography>
        <TextField
          data-cy="paymentMethodCategory"
          select
          label="Payment method"
          name="paymentMethodCategory"
          value={paymentMethodCategory}
          onChange={(event) => {
            if (event.target.value !== PaymentMethodCategory.STRIPE) {
              setFieldValue("paymentMethodType", null);
              setFieldValue("paymentMethodId", null);
            }
            handleChange(event);
          }}
          variant="outlined"
          size="medium"
          fullWidth
          required
        >
          {isWireTransferSupported && <MenuItem value={PaymentMethodCategory.WIRE_TRANSFER}>Wire Transfer</MenuItem>}
          {isBillComSupported && <MenuItem value={PaymentMethodCategory.BILL_COM}>Bill.com</MenuItem>}
          <MenuItem value={PaymentMethodCategory.STRIPE}>{getStripePaymentMethodItemText(entity.currency)}</MenuItem>
        </TextField>

        {paymentMethodCategory === PaymentMethodCategory.WIRE_TRANSFER && (
          <WireTransfer country={entity.country || ""} />
        )}
        {paymentMethodCategory === PaymentMethodCategory.BILL_COM && <BillCom />}
        {paymentMethodCategory === PaymentMethodCategory.STRIPE && (
          <Stack spacing={3}>
            <Typography color="text.secondary" mt={1}>
              Saved card details or bank account details
            </Typography>
            <PaymentMethodSelector
              entityId={entity.id || ""}
              currency={entity.currency || "USD"}
              value={paymentMethodId}
              setFieldValue={setFieldValue}
              savedPaymentMethods={savedPaymentMethods}
            />

            {!!entity.currency && (
              <>
                <Button onClick={openPaymentMethodsModal} variant="outlined" sx={{ alignSelf: "flex-start" }}>
                  {getPaymentMethodModalButtonText(entity.currency)}
                </Button>
                {["GBP", "CAD", "USD"].includes(entity.currency) && (
                  <StripeSetupSession
                    entityId={entity.id}
                    customerId={entity.customer?.id}
                    successUrl={currentUrl.href}
                    cancelUrl={currentUrl.href}
                    newEntity={false}
                  />
                )}
              </>
            )}
          </Stack>
        )}
      </Stack>
    </>
  );
};
