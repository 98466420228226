import { useCallback, useMemo } from "react";

import { AppModel, type PriorityV2 } from "@doitintl/cmp-models";
import { getCollection, useDocumentData, type WithFirebaseModel } from "@doitintl/models-firestore";

import { useApiContext } from "../../../api/context";
import { useCustomerId } from "../../../Components/hooks/useCustomerId";
import { PriorityApi, V1Api } from "./api";

export function usePriorityAPI() {
  const customerId = useCustomerId();
  const api = useApiContext();
  return useMemo(() => new PriorityApi(api, customerId), [api, customerId]);
}

export function useV1Api() {
  const customerId = useCustomerId();
  const api = useApiContext();
  return useMemo(() => new V1Api(api, customerId), [api, customerId]);
}

export function useCompanies() {
  const query = useMemo(() => getCollection(AppModel).doc("priority-v2"), []);

  const transform = useCallback((data: WithFirebaseModel<PriorityV2>) => data.companies, []);

  const [companies] = useDocumentData(query, {
    transform,
  });

  return companies;
}
