import { useEffect, useMemo, useState } from "react";

import { DoitRole, InviteModel, UserModel } from "@doitintl/cmp-models";
import { getCollection } from "@doitintl/models-firestore";
import CloseIcon from "@mui/icons-material/CloseRounded";
import { CircularProgress, DialogContent, TextField } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import isEmail from "is-email";

import { useApiContext } from "../../../api/context";
import { useAccountAssumption } from "../../../Components/hooks/useAccountAssumption";
import { useDoitRoleCheck } from "../../../Components/hooks/useDoitRoles";
import {
  useErrorSnackbar,
  useSnackbar,
  useSuccessSnackbar,
} from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import SimpleDialog from "../../../Components/SimpleDialog";
import { ThreeDotsMenu, type ThreeDotsMenuOption, type ThreeDotsMenuProps } from "../../../Components/ThreeDotsMenu";
import { useAuthContext } from "../../../Context/AuthContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { type InviteWithRole, type Role, type UserOrInviteWithRole, type UserWithRole } from "../../../types";
import { consoleErrorWithSentry } from "../../../utils";
import { downloadFile } from "../../../utils/common";
import { addUsers, updateUsers } from "../db";
import { UploadUsersSnackbarMessage } from "../UploadUsersSnackbarMessage";
import { errorMessages, optionsLabels } from "./consts";
import { convertCsvToUsers, convertUsersToCsvData } from "./convertUsersToCsvData";

type IamTabsThreeDotsMenuProps = {
  roles: Role[];
  users: (UserWithRole | InviteWithRole)[];
  selectedUser?: UserOrInviteWithRole | null;
  onImpersonating: () => void;
};

export const IamTabsThreeDotsMenu = ({ roles, users, selectedUser, onImpersonating }: IamTabsThreeDotsMenuProps) => {
  const [isUploadingUsers, setIsUploadingUsers] = useState(false);
  const isDoitOwnerRole = useDoitRoleCheck(DoitRole.Owners);
  const { customer } = useCustomerContext();
  const api = useApiContext();
  const { currentUser } = useAuthContext();
  const { onOpen: openSnackbar, onClose: closeSnackbar } = useSnackbar();
  const errorSnackbar = useErrorSnackbar();
  const successSnackbar = useSuccessSnackbar();
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteUserEmail, setDeleteUserEmail] = useState("");
  const { isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const accountAssumptionEnabled = useAccountAssumption(customer);
  const handleDownloadUsersCsv = async () => {
    const usersCsv = await convertUsersToCsvData(customer, users);
    downloadFile("users.csv", usersCsv);
  };

  const handleUploadUsersCsv = async (csvFile: File) => {
    setIsUploadingUsers(true);
    try {
      const { usersToCreate, usersToUpdate, errors } = await convertCsvToUsers({
        customer,
        api,
        currentUser,
        csvFile,
        roles,
      });

      await Promise.all([addUsers(usersToCreate), updateUsers(usersToUpdate, customer)]);

      openSnackbar({
        message: (
          <UploadUsersSnackbarMessage
            usersToCreate={usersToCreate}
            usersToUpdate={usersToUpdate}
            errors={errors}
            criticalFailure={false}
          />
        ),
        variant: errors.length === 0 ? "success" : "warning",
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    } catch (error: any) {
      openSnackbar({
        message: <UploadUsersSnackbarMessage criticalFailure errors={error?.message ? [error?.message] : error} />,
        variant: "error",
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    }
    setIsUploadingUsers(false);
  };

  const userOrInviteDisabled = useMemo(() => {
    if (selectedUser?.invite) {
      return;
    }

    return selectedUser?.disabled ?? false;
  }, [selectedUser]);

  useEffect(() => {
    if (isUploadingUsers) {
      openSnackbar({
        message: "uploading file...",
        action: [<CircularProgress key={0} disableShrink={true} size={24} />],
      });
    }
  }, [isUploadingUsers, openSnackbar]);

  const toggleDeactivateUser = async () => {
    try {
      if (selectedUser?.invite) {
        return;
      }

      await api.request({
        method: "post",
        url: `/users/${selectedUser?.email}/disable_user`,
        params: { disable: !userOrInviteDisabled },
        data: {
          customerId: customer.id,
        },
      });

      await selectedUser?.ref.update({ disabled: !selectedUser?.disabled });
    } catch (e) {
      openSnackbar({
        message: userOrInviteDisabled ? errorMessages.CANNOT_ACTIVATE_USER : errorMessages.CANNOT_DEACTIVATE_USER,
        variant: "error",
        autoHideDuration: 3000,
        action: [
          <IconButton key="close" aria-label="Close" color="inherit" onClick={closeSnackbar} size="large">
            <CloseIcon />
          </IconButton>,
        ],
      });
    }
  };

  const deleteUserFromFirebase = async (email: string) => {
    try {
      if (!isEmail(email)) {
        errorSnackbar(errorMessages.INVALID_EMAIL_ERROR);
        return;
      }
      const [existingUserQuerySnap, existingInviteQuerySnap] = await Promise.all([
        getCollection(UserModel).where("email", "==", email).limit(1).get(),
        getCollection(InviteModel).where("email", "==", email).limit(1).get(),
      ]);
      if (!existingUserQuerySnap.empty) {
        errorSnackbar(errorMessages.USER_EXISTS);
        return;
      }
      if (!existingInviteQuerySnap.empty) {
        errorSnackbar(errorMessages.USER_INVITED);
        return;
      }
      await api.request({
        method: "delete",
        url: `/v1/customers/${customer.id}/users/delete`,
        data: {
          email,
        },
      });
      successSnackbar(errorMessages.SUCCESS_SNACKBAR_MESSAGE);
      setDeleteUserEmail("");
      setOpenDialog(false);
    } catch (error: any) {
      consoleErrorWithSentry(error);
      errorSnackbar(errorMessages.FAILURE_SNACKBAR_MESSAGE);
    }
  };

  const options: (ThreeDotsMenuOption | false)[] = [
    isDoitEmployee && {
      label: optionsLabels.REMOVE_USER_FROM_FIREBASE,
      action: () => {
        setOpenDialog(true);
      },
      key: "REMOVE_USER_FROM_FIREBASE",
    },
    { label: optionsLabels.DOWNLOAD_USERS_FILE, action: handleDownloadUsersCsv, key: "DOWNLOAD_USERS_FILE" },
    {
      label: (
        <>
          {optionsLabels.UPLOAD_USERS_FILE}
          <input
            type="file"
            accept=".csv"
            style={{ display: "none" }}
            onChange={(e) => {
              const file = e?.target?.files?.[0] as File;
              e.target.value = ""; // allow firing onChange again
              return handleUploadUsersCsv(file);
            }}
          />
        </>
      ),
      type: "input",
      key: "UPLOAD_USERS_FILE",
    },
    !!selectedUser && {
      label: `${
        userOrInviteDisabled ? optionsLabels.ACTIVATE_SELECTED_USER : optionsLabels.DEACTIVATE_SELECTED_USER
      }  (${selectedUser.displayName ?? selectedUser.email})`,
      action: toggleDeactivateUser,
      key: "ACTIVATE_SELECTED",
    },

    (accountAssumptionEnabled || isDoitOwnerRole) &&
      !!selectedUser && {
        label: optionsLabels.VIEW_AS_SELECTED_USER,
        action: onImpersonating,
        key: "VIEW_AS_SELECTED_USER",
      },
  ];

  return (
    <>
      <SimpleDialog
        open={openDialog}
        title="Remove user from Firebase"
        confirmButtonText="Remove user"
        onConfirm={() => deleteUserFromFirebase(deleteUserEmail)}
        onCancel={() => {
          setOpenDialog(false);
        }}
        dialogProps={{ fullWidth: true }}
      >
        <DialogContent>
          <TextField
            name="email"
            variant="outlined"
            margin="dense"
            label="Email address"
            type="text"
            value={deleteUserEmail}
            onChange={(event) => {
              setDeleteUserEmail(event.target.value);
            }}
            // disabled={loading}
            fullWidth
            required
            autoFocus
          />
        </DialogContent>
      </SimpleDialog>
      <ThreeDotsMenu options={options.filter(Boolean) as ThreeDotsMenuProps["options"]} />
    </>
  );
};
