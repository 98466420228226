import { type Company } from "@doitintl/cmp-models";
import { Stack, Typography } from "@mui/material";

import { useCompanies } from "../../../Components/CreateBillingProfile/BillingForm/hooks";

function findCompanyByCountry(companies: Company[] | undefined, country: string): Company | undefined {
  return companies?.find(({ countries }) => countries.includes(country));
}

export const WireTransfer = ({
  country,
  showPaymentMethodType,
}: {
  country: string;
  showPaymentMethodType?: boolean;
}) => {
  const companies = useCompanies();
  // Present US bank account to customers in other countries
  const companyDetails = findCompanyByCountry(companies, country) ?? findCompanyByCountry(companies, "United States");

  return (
    <Stack pt={2}>
      {showPaymentMethodType && (
        <Stack direction="row" spacing={1}>
          <Typography fontWeight="500">Payment method:</Typography>
          <Typography>Wire transfer</Typography>
        </Stack>
      )}
      {companyDetails?.wireTransfer.map((line) => (
        <Stack key={line.key} direction="row" spacing={1}>
          <Typography fontWeight="500">{line.key}:</Typography>
          <Typography>{line.value}</Typography>
        </Stack>
      ))}
    </Stack>
  );
};
