import { useMemo } from "react";

import { type AssetModel } from "@doitintl/cmp-models";
import { type ModelReference } from "@doitintl/models-firestore";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import TextField, { type TextFieldProps } from "@mui/material/TextField";

import { type Asset } from "../../../../../types";
import { getAssetLabel } from "../../../utils";

type Props = {
  assets: Asset[];
  value: ModelReference<AssetModel>[];
  onChange: (event: any, value: any) => void;
  disabled: boolean;
  label: string;
  helperText?: string;
  textFieldProps?: TextFieldProps;
};

export const AssetsAutocomplete = ({ assets, value, onChange, disabled, label, helperText, textFieldProps }: Props) => {
  const options = useMemo(
    () =>
      assets.map((asset) => ({
        id: asset.id,
        type: asset.data.type,
        label: getAssetLabel(asset),
      })),
    [assets]
  );

  const memoValue = useMemo(
    () => options.filter((option) => value.findIndex((v) => v.id === option.id) > -1),
    [options, value]
  );

  return (
    <Autocomplete
      multiple
      id="assets-autocomplete"
      data-cy="assets-autocomplete"
      options={options}
      value={memoValue}
      onChange={onChange}
      getOptionLabel={(option) => option.label}
      filterSelectedOptions
      disableCloseOnSelect
      disableClearable
      disabled={disabled}
      renderInput={(params) => (
        <TextField
          {...params}
          {...textFieldProps}
          disabled={disabled}
          fullWidth
          label={label}
          margin="dense"
          variant="outlined"
          helperText={helperText}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip size="small" label={option.label} {...getTagProps({ index })} key={option.id} />
        ))
      }
    />
  );
};
