import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { a11yDark as dark, twilight as light } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Alert, Button, Link, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import remarkGfm from "remark-gfm";

type Props = {
  line: string;
  isDarkMode: boolean;
  handleAskQuestion?: (question: string) => void;
};

// Custom Link component to handle different link actions
const CustomLink = ({
  href,
  children,
  handleAskQuestion,
  ...props
}: {
  href?: string;
  children: React.ReactNode;
  handleAskQuestion?: (question: string) => void;
}) => {
  const handleClick = () => {
    if (href && href.includes("runAva") && handleAskQuestion) {
      if (Array.isArray(children) && children[0]) {
        handleAskQuestion(children[0].toString());
      }
    }
  };

  if (href?.includes("runAva")) {
    return (
      <Alert
        action={
          <Button variant="outlined" sx={{ cursor: "pointer" }} onClick={handleClick}>
            Run
          </Button>
        }
        severity="info"
        sx={{ mb: 2 }}
      >
        <Stack direction="row" display="flex" alignItems="center">
          <Typography {...props} target="_self" component="a" fontSize="small">
            {children}
          </Typography>
        </Stack>
      </Alert>
    );
  }

  return (
    <Link {...props} target="_blank" component="a">
      {children}
    </Link>
  );
};

export const CustomMarkdown = ({ line, isDarkMode, handleAskQuestion }: Props) => (
  <ReactMarkdown
    linkTarget="_blank"
    remarkPlugins={[remarkGfm]}
    components={{
      a: ({ href, children, ...props }) => (
        <CustomLink href={href} handleAskQuestion={handleAskQuestion} {...props}>
          {children}
        </CustomLink>
      ),
      code({ inline, className, children, ...props }) {
        const match = /language-(\w+)/.exec(className || "");
        return !inline && match ? (
          <SyntaxHighlighter {...props} style={isDarkMode ? dark : light} language={match[1]} PreTag="div">
            {String(children).replace(/\n$/, "")}
          </SyntaxHighlighter>
        ) : (
          <code {...props} className={className}>
            {children}
          </code>
        );
      },
    }}
  >
    {line}
  </ReactMarkdown>
);
