import { Fragment, useRef, useState } from "react";

import { ArrowDropDown } from "@mui/icons-material";
import { Button, ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper } from "@mui/material";

type Props = {
  options: Array<{ action: () => void; label: string; disabled?: boolean }>;
};

export default function SplitButton({ options }: Props) {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleMenuItemClick = (index) => {
    setSelectedIndex(index);
    setOpen(false);
    options[index].action();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current?.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  // show options not disabled first
  const sortedOptions = options.sort((a) => (a.disabled ? 1 : -1));

  return (
    <Fragment>
      <ButtonGroup
        data-testid="split-button"
        variant="contained"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          data-cy="split-button-main-action"
          onClick={() => {
            options[selectedIndex].action();
          }}
        >
          {options[selectedIndex]?.label}
        </Button>
        <Button
          data-cy="split-button-arrow-down"
          color="primary"
          size="small"
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-label="select mode type"
          aria-haspopup="menu"
          onClick={handleToggle}
        >
          <ArrowDropDown />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "left top" : "left bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu">
                  {(sortedOptions ?? []).map((option, index) => (
                    <MenuItem
                      data-cy={option.label}
                      key={option.label}
                      disabled={option.disabled || index === selectedIndex}
                      selected={index === selectedIndex}
                      onClick={() => {
                        handleMenuItemClick(index);
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
}
