import { useCallback, useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router";
import {
  AssetModel,
  AssetTypeAmazonWebServices,
  AssetTypeBetterCloud,
  AssetTypeDoiTCloudIntelligence,
  AssetTypeDoiTCloudNavigator,
  AssetTypeDoiTCloudSolve,
  AssetTypeDoiTCloudSolveSingleAccelerator,
  AssetTypeGcpPartnerLedPremiumSupport,
  AssetTypeGoogleCloud,
  AssetTypeGSuite,
  AssetTypeLooker,
  AssetTypeOffice365,
  AssetTypeZendesk,
  ContractModel,
  IntegrationModel,
  InternalTierNames,
  type ProductTypes,
  type SubscriptionPaymentTerm,
  TierPackageTypes,
  VendorContractModel,
} from "@doitintl/cmp-models";
import { getCollection, type ModelReference } from "@doitintl/models-firestore";
import BackIcon from "@mui/icons-material/ArrowBackRounded";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  IconButton,
  MobileStepper,
  Paper,
  Step,
  StepLabel,
  Stepper,
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { DateTime } from "luxon";

import { useApiContext } from "../../../api/context";
import LoadingButton from "../../../Components/LoadingButton";
import { useSnackbar } from "../../../Components/SharedSnackbar/SharedSnackbar.context";
import { useAuthContext } from "../../../Context/AuthContext";
import { AccountManagersHooks } from "../../../Context/customer/AccountManagers";
import { ProcurementAssetTypes } from "../../../Context/customer/AssetContext";
import { useContractsContext } from "../../../Context/customer/ContractsContext";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { pickerContext, PickerContextProvider, Scopes, useGapiContext } from "../../../Context/GapiContext";
import { useTier } from "../../../Context/TierProvider";
import { consoleErrorWithSentry } from "../../../utils";
import { sanitizeKeepingLocalDate } from "../../../utils/common";
import { useFullScreen } from "../../../utils/dialog";
import { serverTimestamp } from "../../../utils/firebase";
import mixpanel from "../../../utils/mixpanel";
import { useTurnOffPresentationMode } from "../api";
import { useCancelContract, useDeleteContract } from "../hooks";
import { contractFileSizeBytes, fieldRange, lookerSkuDefaultValues } from "./const";
import { AcceleratorDetailsStep } from "./Steps/AcceleratorDetailsStep/AcceleratorDetailsStep";
import { validateAcceleratorDetailsStep } from "./Steps/AcceleratorDetailsStep/AcceleratorDetailsStepValidator";
import { nonDigitsOutOfRange } from "./Steps/AwsSupportStep/AccountBox/AccountBox";
import { AwsSupportStepWrapper } from "./Steps/AwsSupportStep/AwsSupportStepWrapper";
import { CommitmentStep } from "./Steps/CommitmentStep";
import { creditsByType } from "./Steps/components/Credits";
import { ContractStep } from "./Steps/ContractStep";
import { DciSubscriptionStep } from "./Steps/DciSubscriptionStep/DciSubscriptionStep";
import { validateDciSubscriptionStep } from "./Steps/DciSubscriptionStep/DciSubscriptionStepValidation";
import { DocumentsStep } from "./Steps/DocumentsStep";
import { GcpPartnerLedPremiumSupport } from "./Steps/GcpPartnerLedPremiumSupport";
import { GeneralStep } from "./Steps/GeneralStep";
import { SkusStep, SubscriptionStep as LookerSubscriptionStep } from "./Steps/Looker/LookerSteps";
import { SubscriptionStep } from "./Steps/SubscriptionStep";
import { VendorContractStep } from "./Steps/VendorContractStep";
import { type CommitmentPeriodType, type ContractCredits, type ContractStateType, type VendorContract } from "./types";
import {
  contractApiFormattedPayload,
  createPDFPicker,
  createShowSheetPicker,
  formatContractFromFs,
  formatContractToFs,
  formatVendorContractToFs,
  getSteps,
  hasCommitmentPeriodDiscount,
  ifContractDatesValid,
  ifEditForbidden,
  ifProductWithoutEntity,
  isDoitCloudProduct,
  lookerEstimatedAnnualizedValue,
  lookerSkuStartDates,
  productAllowsNoEntity,
  productSupportsCredits,
  stateDefaultValues,
} from "./utils";

type Props = {
  productTypes: ProductTypes;
  isDoitContractAdmin: boolean;
  isDoitContractOwner: boolean;
};

export const ContractForm = ({ isDoitContractAdmin, isDoitContractOwner, productTypes }: Props) => {
  const history = useHistory();
  const snackbar = useSnackbar();
  const { customer, entities, assets } = useCustomerContext();
  const { customerId, contractId } = useParams<{ customerId: string; contractId: string }>();
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const api = useApiContext();
  const { isMobile } = useFullScreen();
  const { getToken: getGapiToken } = useGapiContext();
  const { getCustomerTier, getInternalTiers } = useTier();
  const turnOffPresentationMode = useTurnOffPresentationMode(api);
  const [state, setState] = useState<ContractStateType>(stateDefaultValues);
  const headerTitle = state.editMode ? "Edit Contract" : "Create a Contract";
  const [accountManagers] = AccountManagersHooks.useAllDoersAccountManagers();
  const handleBackToList = useCallback(() => {
    history.push(`/customers/${customerId}/contracts/contracts-list`);
  }, [customerId, history]);

  useEffect(() => {
    let isMounted = true;

    const loadContract = async () => {
      const contract = (await getCollection(ContractModel).doc(contractId).get()).asModelData();

      const vendorContract =
        contract?.vendorContract && isDoitEmployee ? (await contract.vendorContract.get()).data() : undefined;

      if (!contract) {
        handleBackToList();
        return;
      }

      if (!isMounted) {
        // preventing updating state on unmounted component
        return;
      }

      const customerPricebooksSnapshot = await getCollection(IntegrationModel)
        .doc("cloudhealth")
        .collection("pricebooksAssignments")
        .where("contractId", "==", contractId)
        .get();

      const selectedPricebooks = Object.fromEntries(
        customerPricebooksSnapshot.docs
          .map((doc) => doc.data())
          .map(({ payerId, pricebookId }) => [payerId, pricebookId])
      );

      const contractFields = formatContractFromFs(contract, vendorContract, contract?.vendorContract?.id);

      const isEditForbidden = ifEditForbidden({
        isDoitContractAdmin,
        isDoitContractOwner,
        timeCreated: contract.timeCreated,
        startDate: contract.startDate,
        type: contract.type,
        customer,
        currentUser,
        isActive: contract.active,
      });

      setState((prevState) => ({
        ...prevState,
        editMode: true, // edit mode vs create new doc
        isEditForbidden,
        ...contractFields,
        selectedPricebooks,
      }));
      mixpanel.track("contracts.contract.open", {
        id: contractId,
      });
    };

    if (contractId) {
      loadContract();
    } else {
      const activeEntity = entities.find((entity) => entity.active);
      let accountManager;
      if (customer.accountManager) {
        const id = customer.accountManager.id;
        accountManager = accountManagers?.find((am) => am.id === id);
      }
      setState((prevState) => ({
        ...prevState,
        ...stateDefaultValues,
        entity: activeEntity?.id ?? "",
        accountManager: accountManager?.id ?? "",
      }));
    }

    return () => {
      isMounted = false;
    };
  }, [
    contractId,
    customer,
    handleBackToList,
    currentUser,
    isDoitContractAdmin,
    accountManagers,
    entities,
    isDoitEmployee,
    isDoitContractOwner,
  ]);

  const handleAddCommitmentPeriod = (isVendorContract?: boolean) => {
    setState((prevState) => {
      const contract = isVendorContract ? (prevState.vendorContract as VendorContract) : prevState;
      const commitmentPeriods = contract.commitmentPeriods.slice();
      let startDate = contract.startDate;
      let endDate = contract.endDate;

      if (commitmentPeriods.length > 0) {
        const prevPeriod = commitmentPeriods[commitmentPeriods.length - 1];
        startDate = prevPeriod.endDate;
        endDate = prevPeriod.endDate.isValid ? prevPeriod.endDate.plus({ years: 1 }) : null;

        if (contract.endDate && endDate && endDate > contract.endDate) {
          endDate = contract.endDate;
        }
      }

      commitmentPeriods.push({
        value: null,
        discount: null,
        errors: {},
        startDate,
        endDate: endDate as DateTime,
      });

      if (isVendorContract) {
        return {
          ...prevState,
          vendorContract: {
            ...(prevState.vendorContract as VendorContract),
            commitmentPeriods,
          },
        };
      }
      return {
        ...prevState,
        commitmentPeriods,
      };
    });
  };

  useEffect(() => {
    const commitmentStepNumber = state.type === AssetTypeAmazonWebServices ? 3 : 2;

    if (state.activeStep === commitmentStepNumber && state.isCommitment && state.commitmentPeriods.length === 0) {
      handleAddCommitmentPeriod();
    }
  }, [state.activeStep, state.commitmentPeriods.length, state.isCommitment, state.type]);

  useEffect(() => {
    const vendorCommitmentStepNumber = state.type === AssetTypeAmazonWebServices ? 5 : 4;

    if (
      state.activeStep === vendorCommitmentStepNumber &&
      state.vendorContract?.commitmentType &&
      state.vendorContract?.commitmentPeriods?.length === 0
    ) {
      handleAddCommitmentPeriod(true);
    }
  }, [state.activeStep, state.type, state.vendorContract]);

  const pickerCallback = (name: string, isVendorContract?: boolean) => (data: any) => {
    if (data.action === "loaded") {
      return;
    }

    if (data.action !== window.google.picker.Action.PICKED) {
      setState({ ...state, loading: false });
      return;
    }

    const doc = data.docs[0];
    if (doc.sizeBytes >= contractFileSizeBytes) {
      setState((prevState) => ({ ...prevState, loading: false }));
      snackbar.onOpen({
        message: `File exceeds ${contractFileSizeBytes} bytes`,
        variant: "error",
        autoHideDuration: 5000,
        withClose: true,
      });
      return;
    }

    if (isVendorContract) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
        vendorContract: {
          ...(prevState.vendorContract as VendorContract),
          [name]: doc,
          errors: { ...prevState.errors, contractFile: false },
        },
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      loading: false,
      [name]: doc,
      errors: { ...prevState.errors, contractFile: false },
    }));
  };

  const handleShowPDFPicker = async () => {
    setState({ ...state, loading: true });
    createPDFPicker({ getGapiToken, Scopes, pickerCallback, customer });
  };

  const handleShowPDFPickerVendorContract = async () => {
    setState({ ...state, loading: true });
    createPDFPicker({ getGapiToken, Scopes, pickerCallback, customer, isVendorContract: true });
  };

  const handleShowSheetPicker = async () => {
    setState({ ...state, loading: true });
    createShowSheetPicker({ getGapiToken, Scopes, pickerCallback });
  };

  const handleChangeNumber =
    (name) =>
    ({ target }) => {
      setState((prevState) => {
        let val: number | string = "";

        if (target.value !== "") {
          val = Number(target.value);
          if (val < fieldRange[name].min) {
            val = fieldRange[name].min;
          } else if (val > fieldRange[name].max) {
            val = fieldRange[name].max;
          }
        }

        return {
          ...prevState,
          [name]: val,
          errors: {
            ...prevState.errors,
            [name]: false,
          },
        };
      });
    };

  const handleChange =
    (name) =>
    ({ target }) => {
      setState((prevState) => ({
        ...prevState,
        [name]: target.value,
        errors: {
          ...state.errors,
          [name]: false,
        },
      }));
    };

  const handleChangeAccountManager = (_, selectedValue) => {
    setState((prevState) => ({
      ...prevState,
      accountManager: selectedValue?.value,
      errors: {
        ...state.errors,
        accountManager: false,
      },
    }));
  };

  const handleChangeContractType = (event, selectedValue) => {
    setState((prevState) => {
      const update = {
        entity: prevState.entity,
        type: selectedValue?.value,
        errors: {
          ...state.errors,
          type: false,
          entity: state.errors.entity,
          discountEndDate: false,
          chargePerTerm: false,
        },
        pricelistSheet: null,
        specialDiscount: null,
        gcpFlexSaveOverwrite: fieldRange.gcpFlexSaveOverwrite.default,
        rebaseModifier: 0,
        discountPreemptible: false,
        commitmentPeriods: [],
        isCommitment: false,
        isSoftCommitment: false,
        isRenewal: false,
        endDate: null,
        discountEndDate: null,
        plpsPercent: 0,
        tier: undefined,
        commitmentMonths: undefined,
        paymentTerm: "monthly" as SubscriptionPaymentTerm,
        chargePerTerm: undefined,
        monthlyFlatRate: undefined,
        discount: 0,
      };

      if ([AssetTypeBetterCloud, AssetTypeZendesk].includes(selectedValue?.value)) {
        update.isCommitment = true;
      }

      if (AssetTypeGcpPartnerLedPremiumSupport === selectedValue?.value) {
        update.plpsPercent = fieldRange.plpsPercent.default;
      }

      if (ifProductWithoutEntity(selectedValue?.value, state.pointOfSale)) {
        update.entity = "";
        update.errors = { ...update.errors, entity: false };
      }

      return { ...prevState, ...update };
    });
  };

  const handleChangeDate = (name) => (date) => {
    const today = sanitizeKeepingLocalDate(DateTime.now());
    const dateDT = date ? sanitizeKeepingLocalDate(date) : null;
    const validMonth = dateDT
      ? today.year < dateDT.year || (today.year === dateDT.year && today.month <= dateDT.month)
      : false;

    if ((name === "startDate" && (isDoitContractAdmin || validMonth)) || name !== "startDate") {
      setState((prevState) => ({
        ...prevState,
        [name]: dateDT,
        errors: {
          ...prevState.errors,
          [name]: false,
        },
      }));
      return;
    }
    setState((prevState) => ({
      ...prevState,
      [name]: null,
      errors: {
        ...state.errors,
        [name]: true,
      },
    }));
  };

  const handleCommitmentChange = (isCommitment: boolean, isSoftCommitment: boolean) => {
    setState((prevState) => {
      const { commitmentPeriods, errors } = prevState;
      let { commitmentRollover, endDate } = prevState;

      if (!isCommitment) {
        commitmentPeriods.splice(0);
        commitmentRollover = false;
      }

      if (!isCommitment && !isSoftCommitment) {
        endDate = null;
        errors.endDate = false;
      }

      return {
        ...prevState,
        isCommitment,
        isSoftCommitment,
        commitmentRollover,
        commitmentPeriods,
        endDate,
        errors,
      };
    });
  };

  const handleChangeSelectionControl = (name) => (event) => {
    setState((prevState) => ({
      ...prevState,
      [name]: event.target.checked,
    }));
  };

  const cancelContract = useCancelContract();

  const handleCancel = async () => {
    await cancelContract(contractId, handleBackToList, () => {
      snackbar.onOpen({
        message: "Failed to cancel contract",
        variant: "error",
        autoHideDuration: 5000,
        withClose: true,
      });
    });
  };

  const deleteContract = useDeleteContract();

  const handleDeleteContract = useCallback(async () => {
    await deleteContract(contractId, handleBackToList, () => {
      snackbar.onOpen({
        message: "Failed to delete contract",
        variant: "error",
        autoHideDuration: 5000,
        withClose: true,
      });
    });
  }, [contractId, deleteContract, handleBackToList, snackbar]);

  async function updateContractStatus(active: boolean, terminated: boolean) {
    try {
      await getCollection(ContractModel)
        .doc(contractId)
        .update({
          active,
          terminated,
          updatedBy: { name: currentUser.displayName, email: currentUser.email },
          timestamp: serverTimestamp(),
        });

      if (state.vendorContract?.id) {
        await getCollection(VendorContractModel)
          .doc(state.vendorContract?.id)
          .update({
            active,
            terminated,
            updatedBy: { name: currentUser.displayName, email: currentUser.email },
            timestamp: serverTimestamp(),
          });
      }

      handleBackToList();
    } catch (error) {
      snackbar.onOpen({
        message: "Failed to update status",
        variant: "error",
        autoHideDuration: 5000,
        withClose: true,
      });
      consoleErrorWithSentry(error);
    }
  }

  const disableNextFromSupport = () => {
    setState((prevState) => ({ ...prevState, disableNextFromAwsSupport: true }));
  };

  const handleChangeSupport = (supportAccounts) => {
    setState((prevState) => ({ ...prevState, support: supportAccounts }));
  };

  const handleChangeCredits = (credits) => {
    setState((prevState) => ({
      ...prevState,
      credits,
      errors: {
        ...prevState.errors,
        credits: false,
      },
    }));
  };

  const saveOnlyFullForms = (supItems) => {
    const fullSupportItems = {};
    Object.keys(supItems).forEach((key) => {
      const payerAccount = supItems?.[key];
      const val = typeof payerAccount !== "undefined" ? (payerAccount.discount ?? payerAccount.flatRate) : null;
      if (
        key !== "" &&
        typeof payerAccount !== "undefined" &&
        payerAccount &&
        val !== null &&
        payerAccount.mpaRef !== null &&
        (val !== "" || (val !== null && !nonDigitsOutOfRange(val)))
      ) {
        fullSupportItems[key] = payerAccount;
      }
    });
    handleChangeSupport(fullSupportItems);
  };

  const isStepSkipped = (index: number): boolean => {
    let commitmentStepIndex = 2;
    let vendorContractStepIndex = 3;
    let vendorCommitmentStepIndex = 4;

    if (state.type === AssetTypeAmazonWebServices) {
      commitmentStepIndex++;
      vendorContractStepIndex++;
      vendorCommitmentStepIndex++;
    }

    if (state.type !== AssetTypeLooker && !state.isCommitment && index === commitmentStepIndex) {
      return true;
    }

    if ([AssetTypeAmazonWebServices, AssetTypeGoogleCloud].includes(state.type)) {
      if (!state.isCommitment && [vendorContractStepIndex, vendorCommitmentStepIndex].includes(index)) {
        return true;
      }

      if (!state.vendorContract?.commitmentType && [vendorCommitmentStepIndex].includes(index)) {
        return true;
      }
    }

    return false;
  };

  const handleBack = () => {
    // removing incomplete support items if any were configured in AWS support screen
    if (state.type === AssetTypeAmazonWebServices && state.activeStep === 2 && state.support) {
      saveOnlyFullForms(state.support);
    }

    for (let step = state.activeStep - 1; step >= 0; step--) {
      if (!isStepSkipped(step)) {
        setState({ ...state, activeStep: step });
        return;
      }
    }
  };

  const handleAssetsChange = (event, value) => {
    setState({
      ...state,
      assets: value.filter((v) => !!v).map((v) => getCollection(AssetModel).doc(v.id)),
      errors: {
        ...state.errors,
        assets: false,
      },
    });
  };

  const handleContractChange = (event, value) => {
    setState({
      ...state,
      gcpContract: value.filter((v) => !!v).map((v) => getCollection(ContractModel).doc(v.id)),
      errors: {
        ...state.errors,
        gcpContract: false,
      },
    });
  };

  const handleRemoveCommitmentPeriod = (index, isVendorContract) => () => {
    setState((prevState) => {
      if (isVendorContract) {
        const commitmentPeriods = prevState?.vendorContract?.commitmentPeriods.slice() as CommitmentPeriodType[];
        commitmentPeriods.splice(index, 1);
        return {
          ...prevState,
          vendorContract: {
            ...(prevState.vendorContract as VendorContract),
            commitmentPeriods,
          },
        };
      }
      const commitmentPeriods = prevState.commitmentPeriods.slice();
      commitmentPeriods.splice(index, 1);
      return {
        ...prevState,
        commitmentPeriods,
        commitmentRollover: commitmentPeriods.length < 2 ? false : prevState.commitmentRollover,
      };
    });
  };

  const handleUpdateCommitmentPeriod = (index: number, data: CommitmentPeriodType, isVendorContract?: boolean) => {
    setState((prevState) => {
      if (isVendorContract) {
        const commitmentPeriods = prevState?.vendorContract?.commitmentPeriods.slice() as CommitmentPeriodType[];
        commitmentPeriods.splice(index, 1, data);
        return {
          ...prevState,
          vendorContract: {
            ...(prevState.vendorContract as VendorContract),
            commitmentPeriods,
          },
        };
      }
      const commitmentPeriods = prevState.commitmentPeriods.slice();
      commitmentPeriods.splice(index, 1, data);
      return {
        ...prevState,
        commitmentPeriods,
      };
    });
  };

  const handleChangeSelectedPricebooks = (selectedPricebooks) => {
    setState({ ...state, selectedPricebooks });
  };

  const handleSkusChange = (index, event) => {
    const skus = [...state.lookerSkus];
    skus[index][event.target.name] = event.target.value;
    setState({ ...state, lookerSkus: skus });
  };

  const handleSkusChangeName = (index, value) => {
    const skus = [...state.lookerSkus];
    skus[index].skuName = value;
    setState({ ...state, lookerSkus: skus });
  };

  const handleAddSku = () => {
    const sku = { ...lookerSkuDefaultValues };
    setState({ ...state, lookerSkus: [...state.lookerSkus, sku] });
  };

  const handleDeleteSku = (i) => {
    const lookerSkus = [...state.lookerSkus];
    lookerSkus.splice(i, 1);

    const errors = { ...state.errors };
    if (errors.lookerSkus) {
      errors.lookerSkus.splice(i, 1);
    }

    setState({ ...state, lookerSkus, errors });
  };

  const createUpdateVendorContract = async (): Promise<void | ModelReference<VendorContractModel>> => {
    if (!state.isCommitment || !state.vendorContract?.commitmentType) {
      return;
    }

    const vendorContract = formatVendorContractToFs(state, customer, currentUser, assets);

    try {
      if (state.vendorContract?.id) {
        await getCollection(VendorContractModel).doc(state.vendorContract.id).update(vendorContract);
        return;
      }

      const createdVendorContractRef = await getCollection(VendorContractModel).add(vendorContract);

      return createdVendorContractRef;
    } catch (error) {
      consoleErrorWithSentry("createUpdateVendorContractError", {
        ...vendorContract,
        error,
        id: state.vendorContract?.id,
      });
      throw error;
    }
  };

  const { contracts } = useContractsContext();

  const createUpdateContract = async (
    createdVendorContractRef: void | ModelReference<VendorContractModel>
  ): Promise<null | string> => {
    const contract = formatContractToFs(state, customer, currentUser, assets);
    if (createdVendorContractRef) {
      contract.vendorContract = createdVendorContractRef;
    }

    let createdContractId: string | null = null;
    let contractRef: ModelReference<ContractModel>;

    try {
      // create contract for every asset
      if (!state.editMode && state.type === AssetTypeGcpPartnerLedPremiumSupport) {
        state.assets.forEach(async (asset) => {
          const FormattedContract = formatContractToFs({ ...state, assets: [asset] }, customer, currentUser, assets);
          contractRef = await getCollection(ContractModel).add(FormattedContract);
        });
        return createdContractId;
      }

      if (contractId) {
        contractRef = getCollection(ContractModel).doc(contractId);
        await contractRef.update(contract);
      } else {
        contractRef = await getCollection(ContractModel).add(contract);
        createdContractId = contractRef.id;

        const tiers = getInternalTiers(TierPackageTypes.NAVIGATOR);
        const presentationTierId = tiers?.[InternalTierNames.PRESENTATION]?.id;

        const contractTypes =
          contracts.length === 0
            ? [
                ...ProcurementAssetTypes,
                AssetTypeGSuite,
                AssetTypeOffice365,
                AssetTypeZendesk,
                AssetTypeBetterCloud,
                AssetTypeLooker,
              ]
            : ProcurementAssetTypes;

        if (
          presentationTierId !== undefined &&
          presentationTierId === getCustomerTier(TierPackageTypes.NAVIGATOR)?.ref.id &&
          contractTypes.includes(state.type)
        ) {
          await turnOffPresentationMode(customer.id, {
            navigatorTierId: tiers?.[InternalTierNames.ZERO_ENTITLEMENTS]?.id,
          });
        }
      }

      if (createdVendorContractRef) {
        await createdVendorContractRef.update({ customerContract: contractRef });
      }
    } catch (error) {
      consoleErrorWithSentry("createUpdateContractError", {
        ...contract,
        error,
        id: contractId,
      });
      throw error;
    }

    return createdContractId;
  };

  const createGCPUpdateEvent = async (contractId: string) => {
    if (state.type !== AssetTypeGoogleCloud) {
      return;
    }
    // when the event is handled by cloud analytics, we delete the doc
    await getCollection(ContractModel).doc(contractId).collection("contractUpdates").add({
      type: state.type,
      timestamp: serverTimestamp(),
    });
  };

  const updateAWSPricebooks = () =>
    Object.entries(state.selectedPricebooks)
      .map(([payer_id, price_book_id]) => ({
        price_book_id,
        customer_id: customerId,
        payer_id,
        contract_id: contractId,
      }))
      .map((payload) => api.post("/v1/pricebooks/amazon-web-services/assignments", payload));

  const createUpdateContractWithAPI = async () => {
    const contract = formatContractToFs(state, customer, currentUser, assets);
    const requestPayload = contractApiFormattedPayload(contract);
    const endpoint = state.editMode ? `update/${contractId}` : "create";
    return api.post(`/v1/contract/${endpoint}`, requestPayload);
  };

  const handleSubmit = async () => {
    setState({ ...state, loading: true });

    try {
      if (state.type === AssetTypeAmazonWebServices) {
        await Promise.all(updateAWSPricebooks()).catch((err) => {
          throw new Error(`Pricebook assignment error: ${err.response?.data?.errors}`);
        });
      }

      if (isDoitCloudProduct(state.type)) {
        await createUpdateContractWithAPI();
      } else {
        const createdVendorContractRef = await createUpdateVendorContract();
        const createdContractId = await createUpdateContract(createdVendorContractRef);
        await createGCPUpdateEvent(createdContractId ?? contractId);
      }

      if (state.type === AssetTypeGcpPartnerLedPremiumSupport) {
        api.put(`/support/customers/${customerId}/add-plps-private-message`);
      }

      snackbar.onOpen({
        message: "Contract saved successfully",
        variant: "success",
        autoHideDuration: 5000,
        withClose: true,
      });

      setState({ ...state, loading: false });

      if ([AssetTypeZendesk, AssetTypeBetterCloud].includes(state.type)) {
        history.push(`/customers/${customerId}/assets/${state.type}/create`);
        return;
      }
      handleBackToList();
    } catch (error: any) {
      consoleErrorWithSentry(error);
      setState({ ...state, loading: false });
      snackbar.onOpen({
        message: `Failed to save contract. ${error.message}`,
        variant: "error",
        autoHideDuration: 5000,
        withClose: true,
      });
    }
  };

  const overlappingContractExists = useMemo(() => {
    if (![AssetTypeDoiTCloudNavigator, AssetTypeDoiTCloudSolve].includes(state.type)) {
      return false;
    }

    const filteredContracts = contracts.filter(
      (x) => x.type === state.type && x.active && (state.editMode ? x.id !== contractId : true)
    );
    const newContractStartDate = state.startDate?.toJSDate();
    const newContractEndDate = state.endDate?.toJSDate();

    for (const contract of filteredContracts) {
      const existingContractStartDate = contract.startDate.toDate();
      const existingContractEndDate = contract.endDate?.toDate();

      // Scenario 1: New contract start date is between existing contract start and end date
      if (
        newContractStartDate >= existingContractStartDate &&
        (!existingContractEndDate || newContractStartDate < existingContractEndDate)
      ) {
        return true;
      }

      // Scenario 2: Existing contract start date is between new contract start and end date
      if (
        existingContractStartDate >= newContractStartDate &&
        (!newContractEndDate || existingContractStartDate < newContractEndDate)
      ) {
        return true;
      }
    }

    return false;
  }, [contractId, contracts, state.editMode, state.endDate, state.startDate, state.type]);

  /*
    Flow has different steps depending on the vendor. AWS has extra `Support step`. Looker has 2 different steps.
    Both AWS and Google Cloud have 2 extra steps: vendor contract and vendor commitment step.
    Some steps are skipped depending on whether commitment is selected.
  */
  const getStepContent = (activeStep) => {
    switch (activeStep) {
      case 0:
        return (
          <GeneralStep
            productTypes={productTypes}
            state={state}
            assets={assets}
            handleChangeContractType={handleChangeContractType}
            handleChange={handleChange}
            handleChangeAccountManager={handleChangeAccountManager}
            handleChangeNumber={handleChangeNumber}
            updateContractStatus={updateContractStatus}
            cancelContract={handleCancel}
            isDoitContractAdmin={isDoitContractAdmin}
            isDoitContractOwner={isDoitContractOwner}
            handleAssetsChange={handleAssetsChange}
            deleteContract={handleDeleteContract}
          />
        );

      case 1:
        if (state.type === AssetTypeLooker) {
          return (
            <SkusStep
              lookerSkus={state.lookerSkus}
              lookerSkusError={state.errors.lookerSkus}
              lookerSkuStartDates={lookerSkuStartDates(state)}
              lookerContractStartDate={state.startDate}
              lookerContractDuration={state.lookerContractDuration}
              lookerContractDurationError={state.errors.lookerContractDuration}
              handleChange={handleChange}
              handleChangeDate={handleChangeDate}
              handleAddSku={handleAddSku}
              handleDeleteSku={handleDeleteSku}
              handleSkusChange={handleSkusChange}
              handleSkusChangeName={handleSkusChangeName}
              isEditForbidden={state.isEditForbidden}
              discount={state.discount}
            />
          );
        }

        if (state.type === AssetTypeGcpPartnerLedPremiumSupport) {
          return (
            <GcpPartnerLedPremiumSupport
              assets={assets}
              state={state}
              handleAssetsChange={handleAssetsChange}
              handleChangeNumber={handleChangeNumber}
              handleContractChange={handleContractChange}
            />
          );
        }

        if (state.type === AssetTypeDoiTCloudSolveSingleAccelerator) {
          return (
            <AcceleratorDetailsStep
              state={state}
              setState={setState}
              pageTitle={productTypes.find((option) => option.value === state.type)?.label ?? ""}
            />
          );
        }

        if (state.type === AssetTypeDoiTCloudNavigator || state.type === AssetTypeDoiTCloudSolve) {
          return (
            <SubscriptionStep
              state={state}
              productTypes={productTypes}
              setState={setState}
              handleChangeNumber={handleChangeNumber}
              isDoitContractAdmin={isDoitContractAdmin}
              isDoitContractOwner={isDoitContractOwner}
              overlappingContractExists={overlappingContractExists}
            />
          );
        }

        if (state.type === AssetTypeDoiTCloudIntelligence) {
          return (
            <DciSubscriptionStep
              state={state}
              setState={setState}
              handleChange={handleChange}
              handleChangeNumber={handleChangeNumber}
            />
          );
        }

        return (
          <ContractStep
            state={state}
            assets={assets}
            handleCommitmentChange={handleCommitmentChange}
            handleChangeSelectionControl={handleChangeSelectionControl}
            handleChangeDate={handleChangeDate}
            handleChangeNumber={handleChangeNumber}
            handleAssetsChange={handleAssetsChange}
            handleChange={handleChange}
            handleChangeCredits={handleChangeCredits}
            isDoitContractAdmin={isDoitContractAdmin}
            isDoitContractOwner={isDoitContractOwner}
          />
        );

      case 2:
        if (state.type === AssetTypeLooker) {
          return (
            <LookerSubscriptionStep
              lookerSkus={state.lookerSkus}
              contractStartDate={state.startDate}
              contractDurationMonths={state.lookerContractDuration}
              primaryDomain={customer.primaryDomain}
              invoiceFrequencyMonths={state.lookerInvoiceFrequency}
              invoiceFrequencyError={state.errors.lookerInvoiceFrequency}
              estimatedAnnualizedValue={lookerEstimatedAnnualizedValue(state)}
              handleChange={handleChange}
              isEditForbidden={state.isEditForbidden}
              discount={state.discount}
            />
          );
        }

        if (state.type === AssetTypeGcpPartnerLedPremiumSupport || isDoitCloudProduct(state.type)) {
          return (
            <DocumentsStep
              state={state}
              handleChange={handleChange}
              handleShowPDFPicker={handleShowPDFPicker}
              handleShowSheetPicker={handleShowSheetPicker}
            />
          );
        }

        return state.type === AssetTypeAmazonWebServices ? (
          <Container maxWidth="sm">
            <Grid container spacing={1} justifyContent="center" alignItems="center">
              <AwsSupportStepWrapper
                handleChangeSupport={handleChangeSupport}
                contractSupportErrorBool={state.errors.support ?? false}
                support={state.support}
                disableNext={disableNextFromSupport}
                onChangeSelectedPricebooks={handleChangeSelectedPricebooks}
                selectedPricebooks={state.selectedPricebooks}
                isEditForbidden={state.isEditForbidden}
              />
            </Grid>
          </Container>
        ) : (
          <CommitmentStep
            commitmentPeriods={state.commitmentPeriods}
            commitmentRollover={state.commitmentRollover}
            isEditForbidden={state.isEditForbidden}
            vendor={state.type}
            startDate={state.startDate}
            endDate={state.endDate as DateTime}
            handleChangeSelectionControl={handleChangeSelectionControl}
            handleRemoveCommitmentPeriod={handleRemoveCommitmentPeriod}
            handleUpdateCommitmentPeriod={handleUpdateCommitmentPeriod}
            handleAddCommitmentPeriod={handleAddCommitmentPeriod}
          />
        );

      case 3:
        if (state.type === AssetTypeAmazonWebServices) {
          return (
            <CommitmentStep
              commitmentPeriods={state.commitmentPeriods}
              commitmentRollover={state.commitmentRollover}
              isEditForbidden={state.isEditForbidden}
              vendor={state.type}
              startDate={state.startDate}
              endDate={state.endDate as DateTime}
              handleChangeSelectionControl={handleChangeSelectionControl}
              handleRemoveCommitmentPeriod={handleRemoveCommitmentPeriod}
              handleUpdateCommitmentPeriod={handleUpdateCommitmentPeriod}
              handleAddCommitmentPeriod={handleAddCommitmentPeriod}
            />
          );
        }

        if (state.type === AssetTypeGoogleCloud) {
          return <VendorContractStep state={state} setState={setState} assets={assets} />;
        }

        return (
          <DocumentsStep
            state={state}
            handleChange={handleChange}
            handleShowPDFPicker={handleShowPDFPicker}
            handleShowSheetPicker={handleShowSheetPicker}
          />
        );

      case 4:
        if (state.type === AssetTypeAmazonWebServices) {
          return <VendorContractStep state={state} setState={setState} assets={assets} />;
        }

        if (state.type === AssetTypeGoogleCloud) {
          return (
            <CommitmentStep
              isVendorContract
              commitmentPeriods={state.vendorContract?.commitmentPeriods as CommitmentPeriodType[]}
              isEditForbidden={state.isEditForbidden}
              startDate={state.vendorContract?.startDate as DateTime}
              endDate={state.vendorContract?.endDate as DateTime}
              handleAddCommitmentPeriod={handleAddCommitmentPeriod}
              handleUpdateCommitmentPeriod={handleUpdateCommitmentPeriod}
              handleRemoveCommitmentPeriod={handleRemoveCommitmentPeriod}
            />
          );
        }

        return (
          <DocumentsStep
            state={state}
            handleChange={handleChange}
            handleShowPDFPicker={handleShowPDFPicker}
            handleShowSheetPicker={handleShowSheetPicker}
          />
        );

      case 5:
        if (state.type === AssetTypeAmazonWebServices) {
          return (
            <CommitmentStep
              isVendorContract
              commitmentPeriods={state.vendorContract?.commitmentPeriods as CommitmentPeriodType[]}
              isEditForbidden={state.isEditForbidden}
              startDate={state.vendorContract?.startDate as DateTime}
              endDate={state.vendorContract?.endDate as DateTime}
              handleAddCommitmentPeriod={handleAddCommitmentPeriod}
              handleUpdateCommitmentPeriod={handleUpdateCommitmentPeriod}
              handleRemoveCommitmentPeriod={handleRemoveCommitmentPeriod}
            />
          );
        }

        if (state.type === AssetTypeGoogleCloud) {
          return (
            <DocumentsStep
              state={state}
              handleChange={handleChange}
              handleShowPDFPicker={handleShowPDFPicker}
              handleShowSheetPicker={handleShowSheetPicker}
              handleShowPDFPickerVendorContract={handleShowPDFPickerVendorContract}
            />
          );
        }
        break;

      case 6:
        return (
          <DocumentsStep
            state={state}
            handleChange={handleChange}
            handleShowPDFPicker={handleShowPDFPicker}
            handleShowSheetPicker={handleShowSheetPicker}
            handleShowPDFPickerVendorContract={handleShowPDFPickerVendorContract}
          />
        );

      default:
        return null;
    }
  };

  const steps = getSteps(state.type);

  const handleNext = () => {
    const errors = { ...state.errors };
    const vendorContractErrors = state.vendorContract?.errors ?? {};

    let isError = false;
    let nextStep: number | null = null;

    const validateGeneralStep = () => {
      if (!state.type) {
        errors.type = true;
        isError = true;
      }
      if (
        !state.entity &&
        !ifProductWithoutEntity(state.type, state.pointOfSale) &&
        !productAllowsNoEntity(state.type)
      ) {
        errors.entity = true;
        isError = true;
      }
      if (!state.accountManager) {
        errors.accountManager = true;
        isError = true;
      }
      if (state.type === AssetTypeLooker) {
        if (!state.lookerSalesProcess) {
          errors.lookerSalesProcess = true;
          isError = true;
        }
        if (state.assets.length === 0 && !state.isEditForbidden) {
          errors.assets = true;
          isError = true;
        }
      }

      return isError ? null : state.activeStep + 1;
    };

    const validateCredits = (isVendorContract: boolean): Record<string, Record<string, boolean>> | null => {
      const credits = isVendorContract ? (state.vendorContract?.credits as ContractCredits) : state.credits;
      const errors = {};
      const creditOptions = isVendorContract
        ? creditsByType.vendorContract
        : creditsByType.customerContract[state.type];

      creditOptions.forEach(({ creditsName }) => {
        if (credits[creditsName]) {
          if (credits[creditsName].amount === "" || parseFloat(credits[creditsName].amount) === 0) {
            errors[creditsName] = { amount: true };
          }

          if (credits[creditsName].description.trim() === "") {
            if (!errors[creditsName]) {
              errors[creditsName] = {};
            }

            errors[creditsName].description = true;
          }
        }
      });
      return isEmpty(errors) ? null : errors;
    };

    const validateContractStepForLooker = () => {
      if (state.type === AssetTypeLooker) {
        const minContractDuration = Math.max(...state.lookerSkus.map((sku) => sku.months));
        const invalidContractDuration =
          !state.lookerContractDuration || state.lookerContractDuration < minContractDuration;
        if (invalidContractDuration) {
          errors.lookerContractDuration = true;
          isError = true;
        }
        const lookerSkus = state.lookerSkus;
        const requiredFields = ["skuName", "quantity", "monthlySalesPrice", "months"];
        errors.lookerSkus = lookerSkus.map((lookerSku) => {
          const skuErrors = {} as any;
          requiredFields.forEach((field) => {
            if (lookerSku[field] === null || lookerSku[field] === "") {
              skuErrors[field] = true;
            }
          });
          if (lookerSku.monthlySalesPrice < 0) {
            skuErrors.monthlySalesPrice = true;
          }
          return skuErrors;
        });
        if (errors.lookerSkus.some((error) => Object.keys(error).length > 0)) {
          isError = true;
        }
      }
    };

    const validateContractStep = () => {
      if (state.type === AssetTypeDoiTCloudSolveSingleAccelerator) {
        isError = validateAcceleratorDetailsStep(state, errors);
        return;
      }

      if (state.type === AssetTypeGcpPartnerLedPremiumSupport) {
        errors.assets = !state.assets.length;
        errors.gcpContract = !state.gcpContract.length;
        isError = [errors.assets, errors.gcpContracts].some((error) => error);
        return;
      }

      if (state.type === AssetTypeDoiTCloudIntelligence) {
        isError = validateDciSubscriptionStep(state, errors);
        return;
      }

      if (isDoitCloudProduct(state.type)) {
        const requiredFields = ["tierId", "paymentTerm"];
        requiredFields.forEach((field) => {
          if (!state[field]) {
            errors[field] = true;
          }
        });

        if (overlappingContractExists) {
          errors.startDate = true;
          errors.endDate = true;
        }

        errors.chargePerTerm = !state.chargePerTerm;
        isError = !state.chargePerTerm || Object.values(errors).some((error) => error);

        return;
      }

      if (productSupportsCredits(state.type)) {
        const creditErrors = validateCredits(false);
        if (creditErrors) {
          errors.credits = creditErrors;
          isError = true;
        }
      }

      if (!state.startDate?.isValid) {
        isError = true;
        errors.startDate = true;
        return;
      }

      if (state.type === AssetTypeLooker) {
        validateContractStepForLooker();
        return;
      }

      if ((state.isCommitment || state.isSoftCommitment) && !ifContractDatesValid(state.startDate, state.endDate)) {
        isError = true;
        errors.endDate = true;
      }

      if (
        state.isCommitment &&
        state.type === AssetTypeGoogleCloud &&
        !state.rebaseModifier &&
        state.rebaseModifier !== 0
      ) {
        isError = true;
        errors.rebaseModifier = true;
      }

      if (state.discountEndDate && (!state.discountEndDate.isValid || state.discountEndDate < state.startDate)) {
        isError = true;
        errors.discountEndDate = true;
      }

      if (!state.partnerMargin) {
        isError = true;
        errors.partnerMargin = true;
      }
    };

    const validateCommitmentStep = (isVendorContract?: boolean) => {
      const commitmentPeriods = isVendorContract
        ? (state.vendorContract?.commitmentPeriods as CommitmentPeriodType[])
        : state.commitmentPeriods;

      if (commitmentPeriods.length === 0) {
        return null;
      }

      const startDate = isVendorContract ? state.vendorContract?.startDate : state.startDate;
      const endDate = isVendorContract ? state.vendorContract?.endDate : state.endDate;
      const firstCommitmentPeriod = commitmentPeriods[0];
      const lastCommitmentPeriod = commitmentPeriods[commitmentPeriods.length - 1];
      let errorInCommitmentPeriods = false;

      if (firstCommitmentPeriod.startDate?.toMillis() !== startDate?.toMillis()) {
        firstCommitmentPeriod.errors.startDate = true;
        errorInCommitmentPeriods = true;
      }

      if (lastCommitmentPeriod.endDate?.toMillis() !== endDate?.toMillis()) {
        lastCommitmentPeriod.errors.endDate = true;
        errorInCommitmentPeriods = true;
      }

      commitmentPeriods.forEach((cp, i) => {
        const prevCp = commitmentPeriods[i - 1];

        if (!cp.startDate?.isValid || (prevCp && prevCp.endDate?.toMillis() !== cp.startDate?.toMillis())) {
          cp.errors.startDate = true;
          errorInCommitmentPeriods = true;
        }

        if (!cp.endDate?.isValid || cp.endDate.toMillis() <= cp.startDate.toMillis()) {
          cp.errors.endDate = true;
          errorInCommitmentPeriods = true;
        }

        if (cp.value === null) {
          cp.errors.value = true;
          errorInCommitmentPeriods = true;
        }

        if ((hasCommitmentPeriodDiscount(state.type) || isVendorContract) && cp.discount === null) {
          cp.errors.discount = true;
          errorInCommitmentPeriods = true;
        }
      });

      return !errorInCommitmentPeriods || state.isEditForbidden ? state.activeStep + 1 : null;
    };

    const validateAwsSupportStep = () => {
      errors.support = false;
      if (!state.support || !state.disableNextFromAwsSupport) {
        errors.support = true;
        return null;
      }
      for (const key in state.support) {
        if (key === "") {
          errors.support = true;
          return null;
        }
        const payerAccount = state.support[key] as any;

        if (
          (payerAccount.flatRate === null && payerAccount.discount === null) ||
          (payerAccount.flatRate === "" && payerAccount.flatRate !== 0) ||
          payerAccount.discount === "" ||
          payerAccount.mpaRef === null
        ) {
          errors.support = true;
          return null;
        }

        const val = payerAccount.discount ?? payerAccount.flatRate;
        if (val === "" || (val !== null && nonDigitsOutOfRange(val))) {
          errors.support = true;
          return null;
        }
      }

      const stepFwdByCommitment = state.isCommitment ? 1 : 4;
      return state.activeStep + stepFwdByCommitment;
    };

    const validateDocumentsStep = () => {
      if (state.notes.length > 1000) {
        errors.notes = true;
        isError = true;
      }

      if (!state.contractFile) {
        errors.contractFile = true;
        isError = true;
      }

      if (state.vendorContract?.commitmentType && !state.vendorContract?.contractFile) {
        vendorContractErrors.contractFile = true;
        isError = true;
      }

      return isError ? null : state.activeStep + 1;
    };

    const validateVendorContract = (): number | null => {
      if (state.vendorContract?.commitmentType === undefined) {
        vendorContractErrors.commitmentType = true;
        return null;
      }

      if (state.vendorContract.commitmentType === null) {
        // skipping commitment step if "no vendor contract" selected
        return state.activeStep + 2;
      }

      let isError = false;

      if (!state.vendorContract.startDate?.isValid) {
        isError = true;
        vendorContractErrors.startDate = true;
      }

      if (!state.vendorContract.endDate?.isValid) {
        isError = true;
        vendorContractErrors.endDate = true;
      }

      if (!ifContractDatesValid(state.vendorContract.startDate, state.vendorContract.endDate)) {
        isError = true;
        vendorContractErrors.endDate = true;
      }

      const creditsErrors = validateCredits(true);
      if (creditsErrors) {
        isError = true;
        vendorContractErrors.credits = creditsErrors;
      }

      return isError ? null : state.activeStep + 1;
    };

    switch (state.activeStep) {
      case 0:
        nextStep = validateGeneralStep();
        break;

      case 1:
        validateContractStep();
        if (isError) {
          nextStep = null;
        }
        switch (state.type) {
          case AssetTypeDoiTCloudSolveSingleAccelerator:
            nextStep = state.activeStep + 1;
            break;

          case AssetTypeGoogleCloud:
            nextStep = state.activeStep + (state.isCommitment ? 1 : 4);
            break;

          case AssetTypeAmazonWebServices:
          case AssetTypeGcpPartnerLedPremiumSupport:
          case AssetTypeDoiTCloudNavigator:
          case AssetTypeDoiTCloudSolve:
          case AssetTypeDoiTCloudIntelligence:
            nextStep = state.activeStep + 1;
            break;

          default:
            nextStep = state.activeStep + (state.isCommitment ? 1 : 2);
        }
        break;

      case 2:
        if (state.type === AssetTypeAmazonWebServices) {
          nextStep = validateAwsSupportStep();
          break;
        }

        if (state.type === AssetTypeLooker) {
          if (!state.lookerInvoiceFrequency) {
            errors.lookerInvoiceFrequency = true;
            break;
          }
          nextStep = state.activeStep + 1;
          break;
        }

        if (state.type === AssetTypeGcpPartnerLedPremiumSupport || isDoitCloudProduct(state.type)) {
          nextStep = validateDocumentsStep();
          break;
        }

        nextStep = validateCommitmentStep();
        break;

      case 3:
        if (state.type === AssetTypeAmazonWebServices) {
          nextStep = validateCommitmentStep();
          break;
        }
        if (state.type === AssetTypeGoogleCloud) {
          nextStep = validateVendorContract();
          break;
        }
        nextStep = validateDocumentsStep();
        break;

      case 4:
        if (state.type === AssetTypeAmazonWebServices) {
          nextStep = validateVendorContract();
          break;
        }

        if (state.type === AssetTypeGoogleCloud) {
          nextStep = validateCommitmentStep(true);
          break;
        }

        break;

      case 5:
        if (state.type === AssetTypeAmazonWebServices) {
          nextStep = validateCommitmentStep(true);
          break;
        }

        if (state.type === AssetTypeGoogleCloud) {
          nextStep = validateDocumentsStep();
          break;
        }
        break;

      case 6:
        if (state.type === AssetTypeAmazonWebServices) {
          nextStep = validateDocumentsStep();
        }
        break;

      default:
        break;
    }

    setState({
      ...state,
      errors,
      activeStep: !isError && nextStep !== null ? nextStep : state.activeStep,
      vendorContract: { ...(state.vendorContract as VendorContract), errors: vendorContractErrors },
    });

    if (nextStep === steps.length) {
      handleSubmit();
    }
  };

  const { activeStep, loading } = state;

  if (contractId && !state.editMode) {
    return null;
  }

  return (
    <>
      <Card sx={{ border: "none", boxShadow: "none", paddingTop: 4, paddingBottom: 8 }} data-cy="contract-form">
        {isMobile && (
          <CardHeader
            avatar={
              <IconButton aria-label="Back" onClick={handleBackToList} size="large">
                <BackIcon color="primary" />
              </IconButton>
            }
            title={headerTitle}
            subheader={customer.name}
          />
        )}
        {Boolean(entities) && (
          <>
            <CardContent>
              {!isMobile && (
                <Container maxWidth="lg">
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {steps.map((label, index) => (
                      <Step
                        key={label}
                        completed={activeStep > index && !isStepSkipped(index)}
                        disabled={isStepSkipped(index)}
                      >
                        <StepLabel>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                </Container>
              )}

              <Box sx={{ pt: 3 }}>{getStepContent(activeStep)}</Box>
            </CardContent>
          </>
        )}
        {isMobile && (
          <MobileStepper
            steps={steps.length}
            position="bottom"
            activeStep={activeStep}
            variant="progress"
            nextButton={
              activeStep >= steps.length - 1 ? (
                <LoadingButton
                  color="primary"
                  size="small"
                  onClick={handleNext}
                  data-cy="submit-button"
                  disabled={loading || state.isEditForbidden}
                  loading={loading}
                  mixpanelEventId="contracts.submit"
                >
                  Submit
                  <KeyboardArrowRight />
                </LoadingButton>
              ) : (
                <Button color="primary" size="small" data-cy="next-button" onClick={handleNext}>
                  Next
                  <KeyboardArrowRight />
                </Button>
              )
            }
            backButton={
              <Button
                color="primary"
                size="small"
                data-cy="back-button"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        )}
      </Card>
      {!isMobile && (
        <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0, zIndex: 1 }}>
          <Grid container justifyContent="center">
            <Grid item xs={12}>
              <Divider />
            </Grid>

            <Grid item xs={5}>
              <CardActions>
                <Button
                  color="primary"
                  variant="outlined"
                  data-cy="back-button"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                >
                  Back
                </Button>
              </CardActions>
            </Grid>
            <Grid item>
              <CardActions>
                <Button
                  sx={{ mx: 1 }}
                  color="primary"
                  aria-label="cancel"
                  data-cy="cancel-button"
                  onClick={handleBackToList}
                >
                  Cancel
                </Button>
                {activeStep >= steps.length - 1 ? (
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    data-cy="submit-button"
                    onClick={handleNext}
                    disabled={loading || state.isEditForbidden}
                    loading={loading}
                    mixpanelEventId="contracts.submit"
                  >
                    Submit
                  </LoadingButton>
                ) : (
                  <Button variant="contained" color="primary" data-cy="next-button" onClick={handleNext}>
                    Next
                  </Button>
                )}
              </CardActions>
            </Grid>
          </Grid>
        </Paper>
      )}
    </>
  );
};

const ContractFormWrapper = (props) => (
  <PickerContextProvider>
    <pickerContext.Consumer>
      {({ pickerApiLoaded }) => <ContractForm pickerApiLoaded={pickerApiLoaded} {...props} />}
    </pickerContext.Consumer>
  </PickerContextProvider>
);

export default ContractFormWrapper;
