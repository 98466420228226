import { AssetTypeGoogleCloud } from "@doitintl/cmp-models";
import UploadDoneIcon from "@mui/icons-material/CloudDoneRounded";
import UploadIcon from "@mui/icons-material/Upload";
import { Button, Container, Grid, OutlinedInput, TextField, Typography } from "@mui/material";

import { type ContractStateType } from "../types";
import { ContractFileInput } from "./components/ContractFileInput";

type Props = {
  state: ContractStateType;
  handleChange: (name: string) => ({ target }: { target: any }) => void;
  handleShowPDFPicker: () => Promise<void>;
  handleShowPDFPickerVendorContract?: () => Promise<void>;
  handleShowSheetPicker: () => Promise<void>;
};

export const DocumentsStep = ({
  state,
  handleChange,
  handleShowPDFPicker,
  handleShowSheetPicker,
  handleShowPDFPickerVendorContract,
}: Props) => (
  <Container maxWidth="sm" data-cy="documents-step">
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Typography variant="body1" sx={{ fontWeight: 500 }} mb={2}>
          Customer Contract
        </Typography>
        <OutlinedInput
          fullWidth
          inputComponent={() => (
            <ContractFileInput
              isEditForbidden={state.isEditForbidden}
              contractFile={state.contractFile}
              handleShowPDFPicker={handleShowPDFPicker}
              text="Upload signed contract"
              isError={state.errors.contractFile}
            />
          )}
          disabled={state.isEditForbidden}
        />
      </Grid>

      {state.type === AssetTypeGoogleCloud && state.isCommitment && (
        <Grid item xs={12} mt={1}>
          <Button
            data-cy="upload-pricelist-sheet-button"
            variant="outlined"
            color={state.errors.pricelistSheet ? "error" : "primary"}
            sx={{
              width: "100%",
              justifyContent: "space-between",
            }}
            onClick={handleShowSheetPicker}
            disabled={state.isEditForbidden}
          >
            {state.pricelistSheet ? (
              <>
                <UploadDoneIcon sx={{ marginRight: 2 }} />
                <Typography variant="button" noWrap>
                  {state.pricelistSheet.name}
                </Typography>
                <span />
              </>
            ) : (
              <>
                <UploadIcon sx={{ marginRight: 2 }} />
                <Typography variant="button" noWrap>
                  Select Pricelist Sheet
                </Typography>
                <span />
              </>
            )}
          </Button>
        </Grid>
      )}
      {Boolean(state.vendorContract?.commitmentType) && (
        <Grid item xs={12} mt={3}>
          <Typography variant="body1" sx={{ fontWeight: 500 }} mb={2}>
            Vendor Contract
          </Typography>
          <OutlinedInput
            fullWidth
            inputComponent={() => (
              <ContractFileInput
                isVendorContract
                isEditForbidden={state.isEditForbidden}
                contractFile={state.vendorContract?.contractFile}
                handleShowPDFPicker={handleShowPDFPickerVendorContract as () => Promise<void>}
                text="Upload signed vendor contract"
                isError={state.vendorContract?.errors.contractFile}
              />
            )}
            disabled={state.isEditForbidden}
          />
        </Grid>
      )}

      <Grid item xs={12} mt={3}>
        <Typography variant="body1" sx={{ fontWeight: 500 }} mb={2}>
          Others (Optional)
        </Typography>
        <TextField
          fullWidth
          value={state.notes}
          onChange={handleChange("notes")}
          error={state.errors.notes}
          label="Special terms"
          margin="dense"
          variant="outlined"
          helperText={state.errors.notes ? "Maximum 1000 characters allowed" : "Enter any contract specific notes"}
          multiline
          rows="4"
          disabled={state.isEditForbidden}
        />
      </Grid>

      <Grid item xs={12}>
        <TextField
          fullWidth
          value={state.purchaseOrder}
          onChange={handleChange("purchaseOrder")}
          label="Purchase order"
          margin="dense"
          variant="outlined"
          helperText="Enter the contract purchase order"
          disabled={state.isEditForbidden}
        />
      </Grid>
    </Grid>
  </Container>
);
