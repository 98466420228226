import { Fragment } from "react";

import { type LookerSku, type SkuData } from "@doitintl/cmp-models";
import DeleteIcon from "@mui/icons-material/Delete";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Stack,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type DateTime } from "luxon";

import { roundWithCommas } from "../../../../../utils/common";
import { calculateLookerSubscription } from "./LookerCalculator";
import { skus } from "./skuData";

export type LookerSkuError = {
  skuName: boolean;
  months: boolean;
  quantity: boolean;
  monthlySalesPrice: boolean;
};

export type SkuesStepProps = {
  lookerSkus: LookerSku[];
  lookerSkusError: LookerSkuError[];
  lookerSkuStartDates: DateTime[];
  lookerContractStartDate: DateTime;
  lookerContractDuration: number;
  lookerContractDurationError: boolean;
  handleChange: (name: string) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  handleChangeDate: (name: string) => (date: Date | null) => void;
  handleAddSku: () => void;
  handleDeleteSku: (index: number) => void;
  handleSkusChange: (index: number, event: React.SyntheticEvent) => void;
  handleSkusChangeName: (index: number, value: SkuData) => void;
  isEditForbidden: boolean;
  discount: number;
};

const dateFormat = "dd MMM yyyy";

export const SkusStep = (props: SkuesStepProps) => {
  const {
    lookerSkus,
    lookerSkusError,
    lookerSkuStartDates,
    lookerContractStartDate,
    lookerContractDuration,
    lookerContractDurationError,
    handleChange,
    handleChangeDate,
    handleAddSku,
    handleDeleteSku,
    handleSkusChange,
    handleSkusChangeName,
    isEditForbidden,
    discount,
  } = props;

  let contractEndDate = lookerContractStartDate;
  if (lookerContractStartDate?.isValid && lookerContractDuration > 0) {
    contractEndDate = lookerContractStartDate.plus({ months: lookerContractDuration }).minus({ days: 1 });
  }
  const totalSalesPrices = lookerSkus.map((sku) => {
    if (sku.monthlySalesPrice > 0 && sku.months > 0 && sku.quantity > 0) {
      return sku.monthlySalesPrice * sku.months * sku.quantity;
    }
    return 0;
  });
  const annualizedValue = lookerSkus.reduce(
    (total, sku) =>
      sku.monthlySalesPrice > 0 && sku.months > 0 && sku.quantity > 0
        ? total + sku.monthlySalesPrice * sku.months * sku.quantity
        : total,
    0
  );

  const minContractDuration = Math.max(...lookerSkus.map((sku) => sku.months));
  const invalidContractDuration = lookerContractDuration < minContractDuration;
  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12} sm={6}>
          <DatePicker
            renderInput={(params) => <TextField data-cy="contractStartDate" margin="dense" fullWidth {...params} />}
            label="Overall subscription start date"
            onChange={handleChangeDate("startDate")}
            value={lookerContractStartDate}
            components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
            inputFormat={dateFormat}
            disabled={isEditForbidden}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            data-cy="contractDuration"
            required={true}
            name="contractDuration"
            label="Months"
            onChange={handleChange("lookerContractDuration")}
            margin="dense"
            value={lookerContractDuration}
            error={lookerContractDurationError || invalidContractDuration}
            helperText={invalidContractDuration && "Months must be a positive number and greater than the longest SKU"}
            type="number"
            InputProps={{ inputProps: { min: minContractDuration } }}
            disabled={isEditForbidden}
            fullWidth
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Overall subscription end date:</Typography>
          <Typography variant="body2" display="block" data-cy="contractEndDate">
            {contractEndDate?.isValid && contractEndDate.toFormat(dateFormat)}
          </Typography>
        </Grid>
        {lookerSkus.map((sku, i) => (
          <Fragment key={sku.skuName.googleSku}>
            <Grid item xs={12}>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Typography variant="subtitle1" component="span" fontWeight="medium">
                  SKU {i + 1}
                </Typography>
                {lookerSkus.length > 1 && (
                  <Button
                    color="error"
                    startIcon={<DeleteIcon />}
                    onClick={() => {
                      handleDeleteSku(i);
                    }}
                    data-cy="deleteSkuButton"
                  >
                    Delete SKU
                  </Button>
                )}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Autocomplete
                id="skuName"
                data-cy="skuName"
                disableClearable
                value={sku.skuName}
                onChange={(_, value) => {
                  handleSkusChangeName(i, value);
                }}
                options={skus}
                disabled={isEditForbidden}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    required={true}
                    label="SKU name"
                    error={lookerSkusError?.[i]?.skuName}
                    margin="dense"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                name="googleSku"
                label="Google SKU"
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                value={sku.skuName.googleSku}
                margin="dense"
                disabled={true}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                required={true}
                data-cy="months"
                name="months"
                label="Months"
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                margin="dense"
                value={sku.months}
                error={lookerSkusError?.[i]?.months}
                helperText={
                  "SKU months run backward from the end of the contract. " +
                  "Selecting fewer months than the overall subscription will delay the start date"
                }
                type="number"
                InputProps={{ inputProps: { min: 1, max: lookerContractDuration } }}
                disabled={isEditForbidden}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DatePicker
                renderInput={(params) => <TextField margin="dense" fullWidth {...params} />}
                label="Service start date"
                onChange={() => 0}
                value={lookerSkuStartDates[i]}
                inputFormat={dateFormat}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                required={true}
                name="quantity"
                label="Quantity"
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                margin="dense"
                value={sku.quantity}
                error={lookerSkusError?.[i]?.quantity}
                type="number"
                InputProps={{ inputProps: { min: 1 } }}
                disabled={isEditForbidden}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                }}
                name="monthlyListPrice"
                label="Monthly list price"
                value={sku.skuName.monthlyListPrice}
                disabled={true}
                margin="dense"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                type="number"
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  inputProps: { min: 0 },
                }}
                required={true}
                name="monthlySalesPrice"
                label="Monthly sales price"
                value={sku.monthlySalesPrice}
                error={lookerSkusError?.[i]?.monthlySalesPrice}
                onChange={(event) => {
                  handleSkusChange(i, event);
                }}
                margin="dense"
                disabled={isEditForbidden}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle2">Total sales price:</Typography>
              <Typography variant="body2" display="block" data-cy="totalPrice">
                ${roundWithCommas(totalSalesPrices[i])}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Fragment>
        ))}
      </Grid>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleAddSku}
        data-cy="addSkuButton"
        disabled={isEditForbidden}
      >
        Add an additional SKU
      </Button>
      {discount > 0 && (
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12}>
            <Divider sx={{ mt: 3 }} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Annualized value</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" display="block" data-cy="lookerAnnualizedValue">
              ${roundWithCommas(annualizedValue)}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">{`One-time discount (${discount}%)`}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" display="block" data-cy="lookerDiscount">
              ${roundWithCommas(annualizedValue * (discount / 100))}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="subtitle2">Total annualized value</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="body2" display="block" data-cy="lookerTotalAnnualizedValue">
              ${roundWithCommas(annualizedValue - annualizedValue * (discount / 100))}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export type SubscriptionStepProps = {
  lookerSkus: LookerSku[];
  contractStartDate: DateTime;
  contractDurationMonths: number;
  primaryDomain: string;
  invoiceFrequencyMonths: number;
  invoiceFrequencyError: boolean;
  estimatedAnnualizedValue: number;
  handleChange: (name: string) => (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
  isEditForbidden: boolean;
  discount: number;
};

export const SubscriptionStep = (props: SubscriptionStepProps) => {
  const {
    lookerSkus,
    contractStartDate,
    contractDurationMonths,
    primaryDomain,
    invoiceFrequencyMonths,
    invoiceFrequencyError,
    estimatedAnnualizedValue,
    handleChange,
    isEditForbidden,
    discount,
  } = props;
  const subscriptionTerms = calculateLookerSubscription(
    contractStartDate,
    contractDurationMonths,
    invoiceFrequencyMonths,
    lookerSkus.map((sku) => ({
      pricePerMonth: sku.monthlySalesPrice,
      durationMonths: sku.months,
      quantity: sku.quantity,
    }))
  );
  const formatTermDates = (term) => `${term.startDate.toFormat(dateFormat)} - ${term.endDate.toFormat(dateFormat)}`;
  const getInvoiceFrequencyString = (months) => {
    switch (months) {
      case 1:
        return "Monthly";
      case 3:
        return "Quarterly";
      case 6:
        return "Semi-annual";
      case 12:
        return "Annually";
    }
  };

  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} mb={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Subscription term</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            select
            fullWidth
            label="Invoice frequency"
            value={invoiceFrequencyMonths}
            margin="dense"
            variant="outlined"
            onChange={handleChange("lookerInvoiceFrequency")}
            error={invoiceFrequencyError}
            disabled={isEditForbidden}
          >
            {[
              { label: "Monthly", value: 1 },
              { label: "Quarterly", value: 3 },
              { label: "Semi-annual", value: 6 },
              { label: "Annually", value: 12 },
            ].map((item) => (
              <MenuItem key={item.label} value={item.value} dense>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Confirmation of{" "}
            <Box component="span" fontWeight="fontWeightBold">
              {lookerSkus.length} Looker SKUs
            </Box>{" "}
            for the domain{" "}
            <Box component="span" fontWeight="fontWeightBold">
              {primaryDomain}
            </Box>
            {discount > 0 && (
              <>
                <Box component="span"> with an additional </Box>
                <Box component="span" fontWeight="fontWeightBold">
                  {discount}% discount
                </Box>
                <Box component="span"> from DoiT margin</Box>
              </>
            )}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: "none",
                padding: "16px 8px 8px 0px",
              },
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell align="left">Subscription term</TableCell>
                <TableCell align="center">Invoice frequency</TableCell>
                <TableCell align="right">Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscriptionTerms.map((term) => (
                <TableRow key={formatTermDates(term)}>
                  <TableCell align="left">{formatTermDates(term)}</TableCell>
                  <TableCell align="center">{getInvoiceFrequencyString(invoiceFrequencyMonths)} </TableCell>
                  <TableCell align="right">${roundWithCommas(term.amount)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="subtitle2">Annualized value</Typography>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography variant="body2" display="block" data-cy="lookerAnnualizedValue">
            ${roundWithCommas(estimatedAnnualizedValue)}
          </Typography>
        </Grid>
        {discount > 0 && (
          <>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">{`One-time discount (${discount}%)`}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" display="block" data-cy="lookerDiscount">
                ${roundWithCommas(estimatedAnnualizedValue * (discount / 100))}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="subtitle2">Total annualized value</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body2" display="block" data-cy="lookerTotalAnnualizedValue">
                ${roundWithCommas(estimatedAnnualizedValue - estimatedAnnualizedValue * (discount / 100))}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Container>
  );
};
