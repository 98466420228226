import { InputAdornment, MenuItem, TextField } from "@mui/material";

import { type ContractStateType } from "../../types";

type Props = {
  state: ContractStateType;
  handleChange: (name: string) => ({ target }: { target: any }) => void;
  handleChangeNumber: (name: string) => ({ target }: { target: any }) => void;
};

export const LookerSalesProcessField = ({ state, handleChange, handleChangeNumber }: Props) => (
  <div>
    <TextField
      select
      fullWidth
      label="Looker sales process"
      value={state.lookerSalesProcess}
      helperText="Select Looker sales process"
      margin="dense"
      variant="outlined"
      onChange={handleChange("lookerSalesProcess")}
      error={state.errors?.lookerSalesProcess}
      disabled={state.isEditForbidden}
    >
      {[
        "New Looker Logo Renewal",
        "Existing Looker Customer Renewal",
        "Existing Customer Looker Upsell",
        "Greenfield Looker Logo",
      ].map((value) => (
        <MenuItem key={value} value={value} dense>
          {value}
        </MenuItem>
      ))}
    </TextField>

    <TextField
      fullWidth
      value={state.discount ?? 0}
      onChange={handleChangeNumber("discount")}
      label="Discount"
      margin="dense"
      variant="outlined"
      type="number"
      helperText="Enter the contract discount percentage"
      InputProps={{
        endAdornment: <InputAdornment position="end">%</InputAdornment>,
      }}
      disabled={state.isEditForbidden}
    />
  </div>
);
