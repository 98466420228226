import { type FC, useCallback, useMemo } from "react";

import { EarlyAccessFeature } from "@doitintl/cmp-models";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import isString from "lodash/isString";

import { EarlyAccessFeatureGuard } from "../../../Components/Guard/EarlyAccessFeatureGuard";
import useQueryString from "../../../Components/hooks/useQueryString";
import { Loader } from "../../../Components/Loader";
import { isLocalhost } from "../../../constants";
import { useCustomerContext } from "../../../Context/CustomerContext";
import { useCustomerTeamsChannels } from "./hooks";
import { MsTeamsInstructions } from "./MsTeamsInstructions";
import { TeamConnectionAlert } from "./TeamConnectionAlert";
import { TeamsChannelConnections } from "./TeamsChannelConnections";

export const cyIds = {
  integration: "ms-teams-integration",
};

export const MsTeams: FC = () => {
  const msTeamsAppLink = "https://www.example.com"; // TODO: replace this with the real app link when published

  const theme = useTheme();
  const { customer } = useCustomerContext();
  const { channels, channelsLoading, channelsError } = useCustomerTeamsChannels();
  const qs = useQueryString();
  const payload = useMemo<string | undefined>(() => {
    if (!isString(qs.payload)) {
      return undefined;
    }

    return qs.payload;
  }, [qs.payload]);

  const showChannelConnections = useMemo<boolean>(() => {
    if (channelsError) {
      return false;
    }

    if (!channelsLoading && !channels?.length) {
      // noting to see here - either we've loaded and there are no channels, or channels is undefined.
      return false;
    }

    return true;
  }, [channels, channelsLoading, channelsError]);

  const openTeamsApp = useCallback<() => void>(() => {
    window.open(msTeamsAppLink, "_blank", "noopener");
  }, []);

  /*
    TODO: Remove early access feature guard
      Description: When backend is finished and we're ready to roll out. Await CMP-22412, CMP-22844, CMP-22845.
  */
  return (
    <EarlyAccessFeatureGuard featureName={EarlyAccessFeature.MICROSOFT_TEAMS} customer={customer}>
      <Loader loading={channelsLoading}>
        <Stack data-cy={cyIds.integration} gap={2} sx={{ maxWidth: theme.breakpoints.values.lg }}>
          <Typography variant="h1" mb={2}>
            Microsoft Teams
          </Typography>
          {!!channelsError && isLocalhost && (
            <Alert severity="error">
              Error fetching channels: {channelsError.message} (This message is visible to dev / localhost only)
            </Alert>
          )}
          {!!payload && <TeamConnectionAlert payload={payload} />}
          {showChannelConnections && <TeamsChannelConnections channels={channels!} openApp={openTeamsApp} />}
          {/* Using noop as a placeholder until we have the real link */}
          <MsTeamsInstructions openApp={openTeamsApp} />
        </Stack>
      </Loader>
    </EarlyAccessFeatureGuard>
  );
};
