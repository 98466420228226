import DeleteIcon from "@mui/icons-material/Delete";
import LeftIcon from "@mui/icons-material/KeyboardArrowLeftRounded";
import RightIcon from "@mui/icons-material/KeyboardArrowRightRounded";
import { Button, Grid, InputAdornment, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type DateTime } from "luxon";

import { contractsText } from "../../../../../assets/texts";
import { sanitizeKeepingLocalDate } from "../../../../../utils/common";
import { fieldRange } from "../../const";
import { type CommitmentPeriodType } from "../../types";

type CommitmentPeriodProps = {
  index: number;
  data: CommitmentPeriodType;
  isVendorContract?: boolean;
  onChange: (index: number, data: CommitmentPeriodType, isVendorContract?: boolean) => void;
  onRemove: (index: number, isVendorContract?: boolean) => () => void;
  minDate: DateTime;
  maxDate: DateTime | null;
  showDiscount: boolean;
  disabled: boolean;
  disableDelete: boolean;
};

export default function CommitmentPeriod({
  index,
  data,
  isVendorContract,
  onChange,
  onRemove,
  minDate,
  maxDate,
  showDiscount,
  disabled,
  disableDelete,
}: CommitmentPeriodProps) {
  const { value, startDate, endDate, discount, errors } = data;

  const handleChangeDate = (name: "startDate" | "endDate") => (date) => {
    onChange(
      index,
      {
        ...data,
        [name]: date ? sanitizeKeepingLocalDate(date) : null,
        errors: {
          ...errors,
          [name]: false,
        },
      },
      isVendorContract
    );
  };

  const handleChangeValue = ({ target }) => {
    let value: number | null = null;

    if (target.value !== "") {
      value = Number(target.value);

      if (isNaN(value) || value < 0 || !isFinite(value)) {
        value = null;
      }
    }

    onChange(
      index,
      {
        ...data,
        value,
        errors: {
          ...errors,
          value: false,
        },
      },
      isVendorContract
    );
  };

  const handleChangeDiscount = ({ target }) => {
    let value: number | null = null;

    if (target.value !== "") {
      value = Number(target.value);

      if (!isNaN(value) && isFinite(value)) {
        if (value < fieldRange.discount.min) {
          value = fieldRange.discount.min;
        } else if (value > fieldRange.discount.max) {
          value = fieldRange.discount.max;
        }
      }
    }

    onChange(
      index,
      {
        ...data,
        discount: value,
        errors: {
          ...errors,
          discount: false,
        },
      },
      isVendorContract
    );
  };

  const mdCols = showDiscount ? 6 : 4;

  return (
    <>
      <Grid item xs={6}>
        <Typography sx={{ fontWeight: 500 }} data-cy="commitmentPeriodTitle">
          {contractsText.COMMITMENT_PERIOD_TITLE}&nbsp;{index + 1}
        </Typography>
      </Grid>
      <Grid item xs={6} display="flex" justifyContent="flex-end">
        {!disabled && (
          <Button
            variant="text"
            color="error"
            onClick={onRemove(index, isVendorContract)}
            size="medium"
            startIcon={<DeleteIcon />}
            data-cy="commitmentPeriodDeleteButton"
            disabled={disableDelete}
          >
            <Typography variant="subtitle2">{contractsText.DELETE_COMMITMENT_PERIOD_BUTTON_TEXT}</Typography>
          </Button>
        )}
      </Grid>

      <Grid item xs={6} md={mdCols}>
        <DatePicker
          renderInput={(params) => (
            <TextField
              margin="dense"
              fullWidth
              data-cy="commitmentPeriodStartDate"
              {...params}
              error={(errors?.startDate && !disabled) ?? false}
            />
          )}
          value={startDate}
          onChange={handleChangeDate("startDate")}
          minDate={minDate}
          maxDate={endDate}
          label={contractsText.START_DATE_LABEL}
          components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
          inputFormat="dd LLL, yyyy"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={6} md={mdCols}>
        <DatePicker
          renderInput={(params) => (
            <TextField
              margin="dense"
              fullWidth
              data-cy="commitmentPeriodEndDate"
              {...params}
              error={(errors?.endDate && !disabled) ?? false}
            />
          )}
          value={endDate}
          onChange={handleChangeDate("endDate")}
          minDate={startDate}
          maxDate={maxDate}
          label={contractsText.END_DATE_LABEL}
          components={{ LeftArrowIcon: LeftIcon, RightArrowIcon: RightIcon }}
          inputFormat="dd LLL, yyyy"
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={showDiscount ? 6 : 12} md={mdCols}>
        <TextField
          value={value ?? ""}
          error={(errors?.value && !disabled) ?? false}
          onChange={handleChangeValue}
          label={contractsText.COMMITMENT_PERIOD_AMOUNT_LABEL}
          margin="dense"
          variant="outlined"
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          fullWidth
          data-cy="commitmentPeriodAmount"
          disabled={disabled}
        />
      </Grid>
      {showDiscount && (
        <Grid item xs={6}>
          <TextField
            value={discount ?? ""}
            error={errors?.discount ?? false}
            onChange={handleChangeDiscount}
            label={contractsText.COMMITMENT_PERIOD_DISCOUNT_LABEL}
            margin="dense"
            variant="outlined"
            type="number"
            InputProps={{
              startAdornment: <InputAdornment position="start">%</InputAdornment>,
            }}
            fullWidth
            data-cy="commitmentPeriodDiscount"
            disabled={disabled}
            helperText='Put "0" if there is full margin'
          />
        </Grid>
      )}
    </>
  );
}
