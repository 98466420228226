import { AssetTypeAmazonWebServices, AssetTypeAwsStandalone } from "@doitintl/cmp-models";
import { Button, Container, FormControlLabel, Grid, Switch, Typography } from "@mui/material";
import { type DateTime } from "luxon";

import { contractsText } from "../../../../assets/texts";
import { type CommitmentPeriodType } from "../types";
import { hasCommitmentPeriodDiscount } from "../utils";
import CommitmentPeriod from "./components/CommitmentPeriod";

type Props = {
  isVendorContract?: boolean;
  commitmentPeriods: CommitmentPeriodType[];
  commitmentRollover?: boolean;
  isEditForbidden: boolean;
  vendor?: string;
  startDate: DateTime;
  endDate: DateTime;
  handleChangeSelectionControl?: (name: string) => (event: any) => void;
  handleRemoveCommitmentPeriod: (index: number, isVendorContract?: boolean) => () => void;
  handleUpdateCommitmentPeriod: (index: number, data: CommitmentPeriodType, isVendorContract?: boolean) => void;
  handleAddCommitmentPeriod: (isVendorContract?: boolean) => void;
};

export const CommitmentStep = ({
  isVendorContract,
  commitmentPeriods,
  commitmentRollover,
  isEditForbidden,
  vendor,
  startDate,
  endDate,
  handleChangeSelectionControl,
  handleRemoveCommitmentPeriod,
  handleUpdateCommitmentPeriod,
  handleAddCommitmentPeriod,
}: Props) => (
  <Container maxWidth="sm" data-cy={`${isVendorContract ? "vendor-" : ""}commitment-step`}>
    <Grid container spacing={1} justifyContent="center" alignItems="center">
      <Grid container justifyContent="flex-start">
        <Typography variant="h4" data-cy="total-commitment">
          Total {isVendorContract ? "Vendor " : ""}Commitment: $
          {commitmentPeriods.reduce((acc, cp) => acc + (cp.value ?? 0), 0)}
        </Typography>
      </Grid>
      <Grid container spacing={1} pt={2}>
        {vendor !== AssetTypeAmazonWebServices && vendor !== AssetTypeAwsStandalone && handleChangeSelectionControl && (
          <Grid item xs={12}>
            <FormControlLabel
              control={
                <Switch
                  data-cy="commitment-rollover"
                  disabled={commitmentPeriods.length < 2 || isEditForbidden}
                  checked={commitmentRollover}
                  value={commitmentRollover}
                  onChange={handleChangeSelectionControl("commitmentRollover")}
                  color="primary"
                />
              }
              label={contractsText.COMMITMENT_ROLLOVER_LABEL}
            />
          </Grid>
        )}

        {commitmentPeriods.map((data, i) => {
          const minDate = i === 0 ? startDate : commitmentPeriods[i - 1]?.endDate;
          const maxDate = endDate;
          return (
            <CommitmentPeriod
              key={i}
              index={i}
              data={data}
              isVendorContract={isVendorContract}
              onRemove={handleRemoveCommitmentPeriod}
              onChange={handleUpdateCommitmentPeriod}
              minDate={minDate}
              maxDate={maxDate}
              showDiscount={isVendorContract ? true : hasCommitmentPeriodDiscount(vendor)}
              disabled={isEditForbidden}
              disableDelete={commitmentPeriods.length === 1}
            />
          );
        })}

        <Grid container justifyContent="flex-end" mt={2}>
          <Grid item xs={5}>
            <Button
              variant="outlined"
              color="primary"
              size="small"
              fullWidth
              onClick={() => {
                handleAddCommitmentPeriod(isVendorContract);
              }}
              disabled={
                (commitmentPeriods.length > 0 && commitmentPeriods[commitmentPeriods.length - 1]?.endDate >= endDate) ||
                isEditForbidden
              }
            >
              {contractsText.ADD_COMMITMENT_PERIOD_BUTTON_TEXT}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);
