import { useEffect } from "react";

import { useApiContext } from "../../api/context";
import { useAuthContext } from "../../Context/AuthContext";
import { consoleErrorWithSentry } from "../../utils";

const localStorageKey = "doitUserDomainMigrated";

const useDoitMigration = () => {
  const { currentUser, isDoitEmployee } = useAuthContext({ mustHaveUser: true });
  const api = useApiContext();
  const email = currentUser.email;

  useEffect(() => {
    const makeDoitMigrationRequest = async () => {
      try {
        const response = await api.post("/v1/users/doit-migration");
        if (response.status === 200) {
          localStorage.setItem(localStorageKey, "true");
        }
      } catch (error) {
        consoleErrorWithSentry(error);
      }
    };

    if (isDoitEmployee && email.endsWith("@doit.com") && !localStorage.getItem(localStorageKey)) {
      makeDoitMigrationRequest();
    }
  }, [api, email, isDoitEmployee]);

  return null;
};

export default useDoitMigration;
