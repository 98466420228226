import React, { useCallback, useEffect, useMemo } from "react";

import { AssetTypeDoiTCloudNavigator, CurrencyCodes, TierModel } from "@doitintl/cmp-models";
import { getCollection, useCollectionDataOnce } from "@doitintl/models-firestore";
import { Container, Grid, InputAdornment, MenuItem, Stack, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { type DateTime } from "luxon";

import { formatCurrency } from "../../../../../utils/common";
import { dciCommitmentTermOptions, subscriptionTypeOptions } from "../../const";
import { type ContractStateType } from "../../types";
import { stateDefaultValues } from "../../utils";

type Props = {
  state: ContractStateType;
  setState: React.Dispatch<React.SetStateAction<ContractStateType>>;
  handleChangeNumber: (name: string) => ({ target }: { target: any }) => void;
  handleChange: (name: string) => ({ target }: { target: any }) => void;
};

const tiersOrder = ["essentials", "enhanced", "enterprise"];

export const DciSubscriptionStep = ({ state, setState, handleChange, handleChangeNumber }: Props) => {
  const [tiers] = useCollectionDataOnce(
    getCollection(TierModel)
      .where("packageType", "==", AssetTypeDoiTCloudNavigator)
      .where("name", "in", ["essentials", "enhanced", "enterprise"]),
    {
      idField: "id",
      refField: "ref",
    }
  );

  const dciTiers = useMemo(
    () =>
      tiers
        ?.filter((tier) => tier.displayName.toLowerCase().includes("dci"))
        .sort((a, b) => tiersOrder.indexOf(a.name) - tiersOrder.indexOf(b.name)) ?? [],
    [tiers]
  );

  useEffect(() => {
    if (!state.tierId && dciTiers.length) {
      const tier = dciTiers.find((tier) => tier.name === "essentials");
      setState((prevState) => ({
        ...prevState,
        tierId: tier?.id,
      }));
    }
  }, [dciTiers, setState, state.tierId]);

  useEffect(() => {
    if (state.tierId) {
      const tier = dciTiers.find((tier) => tier.id === state.tierId);
      setState((prevState) => ({
        ...prevState,
        tier,
      }));
    }
  }, [dciTiers, setState, state.tierId]);

  const isEssentials = state.tier?.name === "essentials";

  useEffect(() => {
    if (isEssentials) {
      setState((prevState) => ({
        ...prevState,
        isCommitment: false,
        commitmentMonths: undefined,
        startDate: stateDefaultValues.startDate,
        endDate: stateDefaultValues.endDate,
        minimumServiceFee: undefined,
        cloudSpendPercentage: undefined,
        paymentTerm: undefined,
        errors: {
          ...prevState.errors,
          isCommitment: false,
          commitmentMonths: false,
          startDate: false,
          endDate: false,
          minimumServiceFee: false,
          cloudSpendPercentage: false,
          paymentTerm: false,
        },
      }));
    }
  }, [isEssentials, setState]);

  const onTierChange = useCallback(
    ({ target: { value } }) => {
      const newTier = dciTiers?.find((tier) => tier.id === value);

      setState((prevState) => ({
        ...prevState,
        tierId: value,
        tier: newTier,
        errors: {
          ...prevState.errors,
          tier: false,
        },
      }));
      if (newTier?.name !== "essentials") {
        setState((prevState) => ({
          ...prevState,
          isCommitment: true,
          commitmentMonths: dciCommitmentTermOptions[0],
          endDate: stateDefaultValues.startDate.plus({ months: dciCommitmentTermOptions[0] }),
          minimumServiceFee: newTier?.name === "enhanced" ? 2500 : 10000,
          cloudSpendPercentage: 2,
          paymentTerm: "monthly",
          errors: {
            ...prevState.errors,
            isCommitment: false,
            commitmentMonths: false,
            startDate: false,
            endDate: false,
            minimumServiceFee: false,
            cloudSpendPercentage: false,
            paymentTerm: false,
          },
        }));
      }
    },
    [dciTiers, setState]
  );

  useEffect(() => {
    if (!state.isCommitment) {
      setState((prevState) => ({
        ...prevState,
        commitmentMonths: undefined,
        endDate: null,
      }));
    }
  }, [setState, state.isCommitment]);

  const onCommitmentChange = ({ target }) => {
    setState((prevState) => ({
      ...prevState,
      isCommitment: target.value === "commitment",
    }));
  };

  const onCommitmentMonthsChange = ({ target: { value } }) => {
    if (value === -1) {
      setState((prevState) => ({
        ...prevState,
        commitmentMonths: -1,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        commitmentMonths: Number(value),
        endDate: state.startDate.plus({ months: Number(value) }),
      }));
    }
  };

  const onDateChange = (fieldName) => (date: DateTime<boolean> | null) => {
    if (date) {
      setState((prevState) => ({
        ...prevState,
        [fieldName]: date,
        errors: {
          ...prevState.errors,
          [fieldName]: false,
        },
      }));

      if (!isEssentials && fieldName === "startDate" && state.commitmentMonths !== -1) {
        setState((prevState) => ({
          ...prevState,
          endDate: date.plus({ months: state.commitmentMonths }),
        }));
      }
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="subtitle1" sx={{ fontWeight: 500, mb: 2 }}>
        DoiT Cloud Intelligence™ details
      </Typography>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            value={state.tierId ?? ""}
            onChange={onTierChange}
            label="Tier"
            select
            fullWidth
            error={state.errors.tier}
          >
            {dciTiers.map((tier) => (
              <MenuItem key={tier.id} value={tier.id}>
                {tier.displayName}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={state.isCommitment ? "commitment" : "on-demand"}
            onChange={onCommitmentChange}
            label="Subscription types"
            disabled={isEssentials}
            error={state.errors.isCommitment}
            fullWidth
            select
          >
            {subscriptionTypeOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <TextField
            label="Subscription term"
            onChange={onCommitmentMonthsChange}
            error={state.errors.commitmentMonths}
            disabled={isEssentials || !state.isCommitment}
            fullWidth
            value={state.commitmentMonths ?? ""}
            variant="outlined"
            select
          >
            {dciCommitmentTermOptions.map((option) => (
              <MenuItem key={option} value={option}>
                {option} months
              </MenuItem>
            ))}
            {state.paymentTerm === "monthly" && <MenuItem value={-1}>Custom</MenuItem>}
          </TextField>
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            disableMaskedInput
            label="Subscription start date"
            value={state.startDate}
            onChange={onDateChange("startDate")}
            renderInput={(params) => <TextField {...params} fullWidth error={state.errors.startDate} />}
            inputFormat="dd LLL, yyyy"
          />
        </Grid>

        <Grid item xs={12}>
          <DatePicker
            disableMaskedInput
            label="Subscription end date"
            value={state.endDate}
            onChange={onDateChange("endDate")}
            renderInput={(params) => <TextField {...params} fullWidth error={state.errors.endDate} />}
            disabled={isEssentials || state.commitmentMonths !== -1}
            minDate={state.startDate}
            inputFormat="dd LLL, yyyy"
          />
        </Grid>

        <Grid container item xs={12}>
          <Stack direction={"row"} spacing={1} flexGrow={1}>
            <TextField
              select
              label="Currency"
              value={state.currency}
              onChange={handleChange("currency")}
              disabled={isEssentials}
              sx={{ flexBasis: "112px" }}
            >
              <MenuItem value={CurrencyCodes.USD}>{CurrencyCodes.USD}</MenuItem>
            </TextField>
            <TextField
              fullWidth
              label="Minimum service fee"
              value={state.minimumServiceFee ?? ""}
              onChange={handleChangeNumber("minimumServiceFee")}
              disabled={isEssentials}
              error={state.errors.minimumServiceFee}
              type="number"
            />
          </Stack>
        </Grid>

        <Grid item xs={12}>
          <TextField
            fullWidth
            label="Monthly cloud spend percentage"
            value={state.cloudSpendPercentage ?? ""}
            onChange={handleChangeNumber("cloudSpendPercentage")}
            type="number"
            disabled={isEssentials}
            InputProps={{
              endAdornment: <InputAdornment position="end">%</InputAdornment>,
            }}
            error={state.errors.cloudSpendPercentage}
          />
        </Grid>
      </Grid>

      {(state.paymentTerm === "monthly" || isEssentials) && (
        <Grid mt={2}>
          <Typography variant="subtitle2">Monthly sales price:</Typography>
          <Typography variant="body2">
            {isEssentials && formatCurrency(0, state.currency, 0)}
            {!!state.minimumServiceFee && formatCurrency(state.minimumServiceFee, state.currency, 0)}
            {!!state.minimumServiceFee && !!state.cloudSpendPercentage && " OR "}
            {!!state.cloudSpendPercentage && `${state.cloudSpendPercentage}% of the monthly cloud spend`}
          </Typography>
          {!!state.minimumServiceFee && !!state.cloudSpendPercentage && (
            <Typography color="text.secondary" fontSize={12} lineHeight="20px">
              Whichever is greater will be billed to the customer
            </Typography>
          )}
        </Grid>
      )}
    </Container>
  );
};
