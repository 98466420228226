import { type Dispatch, type SetStateAction, useEffect, useMemo, useState } from "react";

import { AppModel, type MasterPayerAccountsModel } from "@doitintl/cmp-models";
import { getCollection, type QuerySnapshotModel } from "@doitintl/models-firestore";

import { arrayFromDocChange } from "../../../../../Context/customer/arrayFromDocChange";
import { useCustomerContext } from "../../../../../Context/CustomerContext";
import { type payerAccountObj } from "./types";

function updateAccountsListFromQuerySnapshot(
  accountsListStateSetter: Dispatch<SetStateAction<payerAccountObj[]>>,
  querySnapshot: QuerySnapshotModel<MasterPayerAccountsModel>
): void {
  accountsListStateSetter((previousAccountsList) => {
    const newAccountsList = Array.from(previousAccountsList);
    arrayFromDocChange(newAccountsList, querySnapshot, (mpaDoc) => ({
      data: mpaDoc.asModelData(),
      ref: mpaDoc.modelRef,
    }));
    return newAccountsList;
  });
}

export const usePayerAccountsList = () => {
  const mpaCollectionRef = useMemo(
    () => getCollection(AppModel).doc("master-payer-accounts").collection("mpaAccounts"),
    []
  );
  const [customerAccountsList, setCustomerAccountsList] = useState<payerAccountObj[]>([]);
  const [sharedAccountsList, setSharedAccountsList] = useState<payerAccountObj[]>([]);
  const [accountsList, setAccountsList] = useState<payerAccountObj[]>([]);
  const { customer } = useCustomerContext();

  useEffect(() => {
    setCustomerAccountsList([]);

    return mpaCollectionRef.where("customerId", "==", customer.id).onSnapshot((querySnapshot) => {
      updateAccountsListFromQuerySnapshot(setCustomerAccountsList, querySnapshot);
    });
  }, [customer.id, mpaCollectionRef]);

  useEffect(() => {
    setSharedAccountsList([]);

    return mpaCollectionRef.where("tenancyType", "==", "shared").onSnapshot((querySnapshot) => {
      updateAccountsListFromQuerySnapshot(setSharedAccountsList, querySnapshot);
    });
  }, [customer.id, mpaCollectionRef]);

  useEffect(() => {
    setAccountsList(sharedAccountsList.concat(customerAccountsList));
  }, [customerAccountsList, sharedAccountsList]);

  return accountsList;
};
