import { useEffect, useState } from "react";

import { DateTime } from "luxon";

import { useAuthContext } from "../../Context/AuthContext";
import { getCustomerAccountAssumptionSettings } from "../../Pages/Customers/db";
import { type Customer } from "../../types";

export const useAccountAssumption = (customer: Customer) => {
  const { isDoitEmployee } = useAuthContext();
  const [assumptionSettingsEnabled, setAssumptionSettingsEnabled] = useState(false);
  useEffect(() => {
    if (isDoitEmployee) {
      return getCustomerAccountAssumptionSettings(customer.id).onSnapshot((accountAssumptionSettings) => {
        const data = accountAssumptionSettings.asModelData();
        if (data) {
          // by default enabled set to true - in case indefinite assumption is enabled
          let enabled = true;
          if (data.accountAssumptionUntil) {
            const timestamp = DateTime.fromJSDate(data?.accountAssumptionUntil.toDate()).toMillis();
            const curr = DateTime.fromJSDate(DateTime.now().toJSDate()).toMillis();
            enabled = timestamp - curr > 0;
          }
          setAssumptionSettingsEnabled(enabled);
        } else {
          setAssumptionSettingsEnabled(false);
        }
      });
    }
  }, [customer, isDoitEmployee]);
  return assumptionSettingsEnabled;
};
